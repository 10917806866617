import axios from 'axios';

const SearchService = {
    endpoint: process.env.REACT_APP_URL_API,
    getTopSearch: async () => {
        const { data } = await axios.post(SearchService.endpoint, {
            query: `
                query {
                    getTopSearch {
                        word
                    }
                }
            `,
        });
        return data;
    },
    search: async (query) => {
        const { data } = await axios.post(SearchService.endpoint, {
            query: `
                query search($query: String!) {
                    search(query: $query) {
                        query
                        magento_customer (pageSize: 5) {
                            identifier
                            title
                            items {
                                id
                                firstname
                                lastname
                                account_type
                                address
                                avatar
                                url_key
                            }
                        }

                        lvc_job (pageSize: 5) {
                            identifier
                            title
                            items {
                                advertisement_id
                                title
                                description
                                customer {
                                    avatar
                                    customer_id
                                    firstname
                                    lastname
                                    id
                                    account_type
                                    url_key
                                }
                                city
                                state
                                created_at
                                gallery {
                                    image
                                    image_url
                                }
                                salary
                                favorites_id
                                has_contacted
                                url_key
                            }
                        }

                        lvc_forsales (pageSize: 5) {
                            identifier
                            title
                            items {
                                advertisement_id
                                title
                                description
                                customer {
                                    avatar
                                    customer_id
                                    firstname
                                    lastname
                                    id
                                    account_type
                                    url_key
                                }
                                created_at
                                gallery {
                                    image
                                    image_url
                                }
                                salary
                                favorites_id
                                has_contacted
                                url_key
                            }
                        }

                        lvc_event (pageSize: 5) {
                            identifier
                            title
                            items {
                                event_id
                                title
                                description
                                status
                                customer {
                                    avatar
                                    customer_id
                                    firstname
                                    lastname
                                    id
                                    url_key
                                }
                                created_at
                                image_url
                                time_end
                                time_start
                                url_key
                            }
                        }

                        lvc_feed (pageSize: 5) {
                            identifier
                            title
                            items {
                                feed_id
                                content
                                link_title
                                link_description
                                customer {
                                    avatar
                                    customer_id
                                    firstname
                                    lastname
                                    id
                                    url_key
                                }
                                created_at
                                media_gallery {
                                    id
                                    media_type
                                    path
                                    video_thumbnail
                                    video_url
                                }
                                privacy
                                total_like
                                total_comment
                            }
                        }

                        magento_catalog_product (pageSize: 5) {
                            identifier
                            title
                            items {
                                id
                                name
                                image {
                                    url
                                }
                                sku
                                description {
                                    html
                                }
                                price_range {
                                    maximum_price {
                                        final_price {
                                            value
                                        }
                                    }
                                }
                                url_key
                            }
                        }

                    }
                }
            `,
            variables: {
                query,
            },
        });
        return data;
    },
};

export default SearchService;
