import CryptoJSParse from 'crypto-js/enc-utf8';
import CryptoJSDecrypt from 'crypto-js/aes';
import CryptoJSCore from 'crypto-js/core';

const key = CryptoJSParse.parse('1241234567abasw4124123dsa7812iii');

const getIv = (knz, pnz) => {
    let string = '';
    pnz?.split(',')?.forEach((item) => {
        string = string + knz[+item - 1];
    });
    return CryptoJSParse.parse(string);
};

const decode = (encryptedData, iv) => {
    const decrypted = CryptoJSDecrypt.decrypt(encryptedData, key, {
        iv,
        mode: CryptoJSCore.mode.CBC,
        padding: CryptoJSCore.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJSParse);
};

const decodeFromBase64 = (base64) => {
    if (!base64) return '';
    return decodeURIComponent(escape(window.atob(base64)));
};

export { decode, getIv, decodeFromBase64 };
