import React from 'react';
import ReactDOM from 'react-dom';
import RequestUpdateAppVersion from 'components/RequestUpdateVersion';

const showModalRequestUpdateAppVersion = () => {
    const rootEl = document.getElementById('root');
    const divEl = document.createElement('div');
    divEl.setAttribute('id', 'request-update-version');
    rootEl.append(divEl);
    ReactDOM.render(<RequestUpdateAppVersion />, divEl);
};

export default showModalRequestUpdateAppVersion;
