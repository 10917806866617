const scrollTop = (to = "") => {
    const divScroll = document.getElementById(`${to}`);
    let currentScrollPosition = 0;
    if (divScroll) {
        currentScrollPosition = divScroll.scrollTop;
        // eslint-disable-next-line no-undef
        sessionStorage.setItem('scrollPosition', currentScrollPosition);
        divScroll.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const yOffset = -50;
    const y = document?.querySelector('.main')?.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    return currentScrollPosition;
};

export const restoreScrollPosition = (divId) => {
    const divScroll = document.getElementById(`${divId}`);

    if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-undef
        const savedPosition = sessionStorage.getItem('scrollPosition');

        if (divScroll && savedPosition) {
            divScroll.scrollTop = parseInt(savedPosition, 10);
        }
    }
};

export default scrollTop;
