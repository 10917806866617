import {
    gql
} from '@apollo/client'

const ADD_FAVORITIES_QUERY = gql`
    mutation addAdsFavorites($adsId: Int!) {
        addAdsFavorites (
            advertisement_id: $adsId
        ) {
            result
            favorites_id
        }
    }
    `

const DELETE_FAVORITIES_QUERY = gql`
    mutation deleteFavorites($favsId: Int!) {
        deleteFavorites(
            favorites_id: $favsId
        ) {
            result
        }
    }
`

const GET_ADS_BY_ID_QUERY = gql`
    query adsDetail($id: String) {
        adsDetail(id: $id) {
            advertisement_id
            type
            status
            enable
            customer_id
            title
            salon_address
            city
            postal_code
            country
            state
            salary
            price
            description
            created_at
            salon_id
            gallery {
                image
                image_url
                image_bot
            }
            customer {
                email
                firstname
                lastname
            }
            contact {
                ads_apply_id
                customer_id
                name
                phone
                avatar
                email
                message
                is_contact
                created_at
                updated_at
                url_key
            }
            favorites_id
            meta_title
            meta_keywords
            meta_description
            url_key
        }
}
`

const CREATE_CONTACT_QUERY = gql`
 mutation createContactAds($input: ApplyAdsCreateInput!) {
    createContactAds(input: $input) {
      result
    }
  }

`

const extractQueryInfo = (query) => ({
    query: query.loc.source.body,
    name: query.definitions[0].name.value,
});

const ADS_QUERY = {
    ADD_FAVORITES: extractQueryInfo(ADD_FAVORITIES_QUERY),
    DELETE_FAVORITIES: extractQueryInfo(DELETE_FAVORITIES_QUERY),
    GET_ADS_BY_ID: extractQueryInfo(GET_ADS_BY_ID_QUERY),
    CREATE_CONTACT: extractQueryInfo(CREATE_CONTACT_QUERY),
};

export default ADS_QUERY;

// const ADS_QUERY = {
//     ADD_FAVORITES: {
//         query: ADD_FAVORITIES_QUERY.loc.source.body,
//         name: ADD_FAVORITIES_QUERY.definitions[0].name.value,
//     },
//     DELETE_FAVORITIES: {
//         query: DELETE_FAVORITIES_QUERY.loc.source.body,
//         name: DELETE_FAVORITIES_QUERY.definitions[0].name.value,
//     },
//     GET_ADS_BY_ID: {
//         query: GET_ADS_BY_ID_QUERY.loc.source.body,
//         name: GET_ADS_BY_ID_QUERY.definitions[0].name.value,
//     },
//     CREATE_CONTACT: {
//         query: CREATE_CONTACT_QUERY.loc.source.body,
//         name: CREATE_CONTACT_QUERY.definitions[0].name.value,
//     }
// }

// export default ADS_QUERY
