

const parseStatus = (status = ' ') => {
    switch (status.toLowerCase()) {
        case 'published':
            return 'upcomming';
        case 'running':
            return 'live'
        default:
            return ''
    }
}
export default parseStatus