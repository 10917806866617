import wm1 from 'assets/images/wm1.png';
import wm2 from 'assets/images/wm2.png';
import wm3 from 'assets/images/wm3.png';
import wm4 from 'assets/images/wm4.png';
import wm5 from 'assets/images/wm5.png';
import wm6 from 'assets/images/wm6.png';
import wm7 from 'assets/images/wm7.png';
import wm8 from 'assets/images/wm8.png';
import wm9 from 'assets/images/wm9.png';
import wm10 from 'assets/images/wm10.png';
import wm11 from 'assets/images/wm11.png';
import wm12 from 'assets/images/wm12.png';
import wm13 from 'assets/images/wm13.png';
import wm14 from 'assets/images/wm14.png';
import wm15 from 'assets/images/wm15.png';
import wm16 from 'assets/images/wm16.png';
import wm17 from 'assets/images/wm17.png';
import wm18 from 'assets/images/wm18.png';
import wm19 from 'assets/images/wm19.png';
import wm20 from 'assets/images/wm20.png';

export const waterMarkImages = {
    1: {
        id: 1,
        img: wm16,
        textColor: '#914144',
    },
    2: {
        id: 2,
        img: wm17,
        textColor: '#be0566',
    },
    3: {
        id: 3,
        img: wm18,
        textColor: '#bd3268',
    },
    4: {
        id: 4,
        img: wm19,
        textColor: '#79653c',
    },
    5: {
        id: 5,
        img: wm20,
        textColor: '#de6a25',
    },
    6: {
        id: 6,
        img: wm1,
        textColor: '#FFFFFF',
    },
    7: {
        id: 7,
        img: wm2,
        textColor: '#FFFFFF',
    },
    8: {
        id: 8,
        img: wm3,
        textColor: '#905700',
    },
    9: {
        id: 9,
        img: wm4,
        textColor: '#007FBC',
    },
    10: {
        id: 10,
        img: wm5,
        textColor: '#0D7F0D',
    },
    11: {
        id: 11,
        img: wm6,
        textColor: '#FFFFFF',
    },
    12: {
        id: 12,
        img: wm7,
        textColor: '#FFFFFF',
    },
    13: {
        id: 13,
        img: wm8,
        textColor: '#D86B91',
    },
    14: {
        id: 14,
        img: wm9,
        textColor: '#604135',
    },
    15: {
        id: 15,
        img: wm10,
        textColor: '#D86B91',
    },
    16: {
        id: 16,
        img: wm11,
        textColor: '#D86B91',
    },
    17: {
        id: 17,
        img: wm12,
        textColor: '#4E85B4',
    },
    18: {
        id: 18,
        img: wm13,
        textColor: '#D86B91',
    },
    19: {
        id: 19,
        img: wm14,
        textColor: '#D86B91',
    },
    20: {
        id: 20,
        img: wm15,
        textColor: '#3672A5',
    },
};

const getWaterMark = (id) => {
    return waterMarkImages[id];
};

export default getWaterMark;
