import { parseJSON } from 'utils';

import axios from 'axios';

const SalonService = {
    endpoint: process.env.REACT_APP_URL_API,
    getSalonListByCustomer: async () => {
        const { data } = await axios.post(SalonService.endpoint, {
            query: `
                query  {
                    getSalonList{
                        items  {
                            entity_id
                            name
                            status
                            description
                            customer_id
                            city
                            country_id
                            postcode
                            region
                            region_id
                            street
                            gallery {
                              image
                              image_url
                            }
                            lat
                            lng
                   
                            business_license {
                                file
                                file_url
                            }
                        }
                        page_info {
                            current_page,
                            page_size,
                            total_page
                        }
                
                        
                    }
                }
        `,
        });
        return data;
    },
    getSalonDetail: async (idSalon) => {
        const { data } = await axios.post(SalonService.endpoint, {
            query: `
          query getSalonDetail($idSalon: Int!) {
            getSalonDetail(salon_id: $idSalon ) {
              entity_id
              name
              status
              description
              customer_id
              city
              country_id
              postcode
              region
              region_id
              street
              gallery {
                  id
                  image
                  image_url
              }
              business_license {
                  id
                  file
                  file_url
              }
              ads {
                  salary
                  type
                  advertisement_id
                  title
                  salon_address
                  city
                  postal_code
                  salon_id
                  customer_id
                  country
                  state
                  price
                  created_at
                  gallery {
                      image
                      image_url

                  }
                  customer {
                      email
                      firstname
                      lastname
                      avatar
                      customer_id
                  }
              }
          }
          }
        `,
            variables: {
                idSalon,
            },
        });
        return data;
    },
    requestSalonOwnerRegistration: async (value) => {
        const states = parseJSON(localStorage.getItem('states')) || [];
        const salonRegion = {};
        states?.map((item) => {
            if (item?.name === value?.state) {
                salonRegion.region = item?.name;
                salonRegion.region_code = item?.code;
                salonRegion.region_id = item?.id;
            }
            return item;
        });
        const input = {
            id: null,
            name: value?.name,
            description: value?.about,
            address: {
                region: salonRegion,
                country_code: 'US',
                street: value?.address,
                postcode: value?.zip,
                city: value?.city,
                lat: value?.lat,
                lng: value?.lng,
            },
            gallery: value?.gallery?.fileList?.map((item) => ({
                name: item?.name?.replaceAll(' ', ''),
                base64_encoded_file: item?.base64_encoded_file,
            })),
            business_license: value?.business_license?.fileList?.map((item) => ({
                name: item?.name?.replaceAll(' ', ''),
                base64_encoded_file: item?.base64_encoded_file,
            })),
        };
        const { data } = await axios.post(SalonService.endpoint, {
            query: `
        mutation requestSalonOwnerRegistration ($input: RequestSalonOwnerInput!) {
          requestSalonOwnerRegistration (input: [$input]) {
            salon_id
          }
        }
      `,
            variables: {
                input,
            },
        });
        return data;
    },
    requestUpdateSalon: async (input) => {
        const { data } = await axios.post(SalonService.endpoint, {
            query: `
        mutation requestUpdateSalon ($input: [RequestUpdateSalonInput!]) {
          requestUpdateSalon (input: $input) {
            result
          }
        }
      `,
            variables: {
                input,
            },
        });
        return data;
    },
    requestUpdateSalonWithOnlyDescription: async (input) => {
        const { data } = await axios.post(SalonService.endpoint, {
            query: `
        mutation updateSalon ($input: SalonInput!) {
          updateSalon (input: $input) {
            result
          }
        }
      `,
            variables: {
                input,
            },
        });
        return data;
    },
    deleteSalon: async (idSalon) => {
        const { data } = await axios.post(SalonService.endpoint, {
            query: `
      mutation deleteSalon($idSalon: Int!) {
        deleteSalon(
            salon_id: $idSalon
        ) {
            result
          }
        }
        `,
            variables: {
                idSalon,
            },
        });
        return data;
    },
};

export default SalonService;
