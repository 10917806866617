

import { useMutation } from "react-query"
import FOLLOW_SERVICE from "services/refactor/follow"

// ============================================================
// FOLLOW QUERIES
// ============================================================

export const useAddFollower = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return FOLLOW_SERVICE.addFollower({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useUnFollow = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return FOLLOW_SERVICE.unFollower({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}