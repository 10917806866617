import { message } from 'antd';
import './index.less';

const Message = {
    success: (content) => message.success(content),
    error: (content) => message.error(content),
    custom: (content, type, className) =>
        message.open({
            type,
            className,
            content,
        }),
};

export default Message;
