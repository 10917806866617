import { keyUser, keyToken, keyRefreshToken } from '../variable';

export const saveAuthToken = (data) => {
    localStorage.setItem(keyUser, JSON.stringify(data?.user));
    localStorage.setItem(keyToken, JSON.stringify(data?.token));
    localStorage.setItem(keyRefreshToken, JSON.stringify(data.refreshToken));
    localStorage.setItem('user_status', JSON.stringify(data?.user?.authentication_status));
    localStorage.setItem(
        'salon_owner',
        JSON.stringify({
            is_salon_owner: data?.user?.is_salon_owner,
            salon_owner_status: data?.user?.salon_owner_status,
        })
    );
};

export const removeAuthToken = () => {
    localStorage.removeItem(keyUser);
    localStorage.removeItem(keyToken);
    localStorage.removeItem('user_status');
    localStorage.removeItem('salon_owner');
};


export const getAccountType = (accountInfo) => {
    let type = 'Member';
    if (accountInfo?.account_type === '2') type = 'Manicurist';
    if (accountInfo?.account_type === '3') type = 'Nail Master';
    return type;
};

export const getAuthToken = async () => {
    const user = await JSON.parse(localStorage.getItem(keyUser));
    const token = await JSON.parse(localStorage.getItem(keyToken));
    const refreshToken = await JSON.parse(localStorage.getItem(keyRefreshToken));

    return {
        user,
        token,
        refreshToken,
    };
};
