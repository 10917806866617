export const isAddressMatchKeyType = (key) => {
    return [
        'street_number',
        'route',
        'locality',
        'sublocality_level_1',
        'sublocality',
        'administrative_area_level_1',
        'administrative_area_level_2',
        'country',
        'ward',
        'neighborhood',
        'premise',
        'subpremise',
        'natural_feature',
        'state',
        'postal_code',
    ].includes(key);
};

export const addItemForKey = (key, value, input, force = false) => {
    if (force) {
        input[key] = value;
    } else {
        const isExist = input[key];
        if (!isExist) {
            input[key] = value;
        }
    }
};

export const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    resolve({ lat, lng });
                },
                function (error) {
                    reject(new Error('Error getting current position: ' + error.message));
                }
            );
        } else {
            reject(new Error('Geolocation is not supported by this browser.'));
        }
    });
};

export const getRegionCode = (text) => {
    if (!text) return;
    const words = text.split(' ');
    let initials;
    if (words?.length === 1) {
        initials = words.map((word) => word.substring(0, 2));
    } else {
        initials = words.map((word) => word.substring(0, 1));
    }
    return initials.join('');
};

export const randomOffset = () => Math.random() * 0.0009 - 0.0001;
