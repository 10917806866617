import axios from 'axios';

const FeedBackService = {
    endpoint: process.env.REACT_APP_URL_API,
    getCategories: async (pageSize, currentPage) => {
        const { data } = await axios.post(FeedBackService.endpoint, {
            query: `
                query LofHelpDeskCategories($pageSize: Int,$currentPage: Int) {
                        LofHelpDeskCategories (  
                            pageSize: $pageSize
                            currentPage: $currentPage
                        ) {
                            total_count
                            items {
                                category_id
                                title
                            }
                        }
                    }
                `,
            variables: {
                pageSize,
                currentPage,
            },
        });
        return data;
    },
    createFeedback: async (input, tokenRecaptcha) => {
        const { data } = await axios.post(
            FeedBackService.endpoint,
            {
                query: `
                mutation LofHelpDeskCreateTicket($input: TicketInput!) {
                    LofHelpDeskCreateTicket(input: $input)
                    {
                        ticket_id
                    }
                }
        `,
                variables: {
                    input,
                },
            },
            {
                headers: {
                    'X-reCaptcha': tokenRecaptcha,
                },
            }
        );
        return data;
    },
    getListFeedbackByCustomer: async (pageSize, currentPage, filter) => {
        const { data } = await axios.post(FeedBackService.endpoint, {
            query: `
                query LofHelpDeskTicketList($pageSize: Int,$currentPage: Int, $filter: TicketFilterInput) {
                        LofHelpDeskTicketList (  
                            pageSize: $pageSize
                            currentPage: $currentPage
                            filter: $filter
                        ) {
                            total_count
                            items {
                                ticket_id
                                code
                                category_id
                                department
                                department_id
                                status
                                status_id
                                subject
                                description
                                created_at
                                images {
                                    id
                                    path
                                }
                            }
                        }
                    }
                `,
            variables: {
                pageSize,
                currentPage,
                filter,
            },
        });
        return data;
    },
    sendMessageFeedback: async (input) => {
        const { data } = await axios.post(FeedBackService.endpoint, {
            query: `
                mutation LofHelpDeskSendMessageTicket($input: MessageInput!) {
                    LofHelpDeskSendMessageTicket(input: $input)
                    {
                        message_id
                        ticket_id
                        user_id
                        body
                        customer_id
                        customer_name
                        created_at
                        updated_at
                    }
                }
        `,
            variables: {
                input,
            },
        });
        return data;
    },
    getMessageFeedback: async (pageSize, currentPage, ticket_id) => {
        const { data } = await axios.post(FeedBackService.endpoint, {
            query: `
                query getMessageTicket($pageSize: Int,$currentPage: Int, $ticket_id: Int!) {
                        getMessageTicket (  
                            pageSize: $pageSize
                            currentPage: $currentPage
                            ticket_id: $ticket_id
                        ) {
                            total_count
                            items {
                                message_id
                                ticket_id
                                user_id
                                customer_id
                                customer_name
                                user_name
                                body
                                created_at
                                updated_at
                                attachment {
                                    id
                                    path
                                }
                            }
                        }
                    }
                `,
            variables: {
                pageSize,
                currentPage,
                ticket_id,
            },
        });
        return data;
    },
    updateStatusTicket: async (ticket_id, status_id) => {
        const { data } = await axios.post(FeedBackService.endpoint, {
            query: `
                mutation updateStatusTicket($ticket_id: Int!, $status_id: Int) {
                    updateStatusTicket(
                        ticket_id: $ticket_id
                        status_id : $status_id
                    ) {
                        result
                    }
                }
        `,
            variables: {
                ticket_id,
                status_id,
            },
        });
        return data;
    },
    getRequestDetail: async (request_id) => {
        const { data } = await axios.post(FeedBackService.endpoint, {
            query: `
                query getRequestDetail($request_id: Int!) {
                    getRequestDetail(request_id: $request_id) {
                        entity_id
                        topic_id
                        status
                        value_update
                        created_at
                        comment
                    }
                }
            `,
            variables: {
                request_id: +request_id,
            },
        });
        return data;
    },
};

export default FeedBackService;
