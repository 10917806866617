import { useQuery } from "react-query"
import { QUERY_KEYS } from "../queryKeys"
import HOME_SERVICE from "services/home"

// ============================================================
// HOME QUERIES
// ============================================================
export const useGetHotTopic = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_HOT_TOPIC],
        queryFn: HOME_SERVICE.getHotTopic,
        staleTime: 1000 * 60 * 1,
        // refetchOnWindowFocus: true,
        // refetchOnReconnect: true,
    })
}

export const useGetMetaData = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_META_DATA],
        queryFn: HOME_SERVICE.getMetaData,
        staleTime: 1000 * 60 * 1,
        refetchOnReconnect: true,
    })
}