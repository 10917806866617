import imageValidate from 'utils/imageValidate';
import defaultAvatar from 'assets/images/avatar.webp';
import { parseJSON } from 'utils';

import axios from 'axios';

const ProfileService = {
  endpoint: process.env.REACT_APP_URL_API,
  confirmPin: async (pin) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation confirmPassword ($password: String!) {
            confirmPassword (password: $password) {
              result
            }
          }
  `,
      variables: {
        password: pin,
      },
    });
    return data;
  },
  changePin: async (currentPassword, newPassword) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation changeCustomerPassword ($currentPassword: String!, $newPassword: String!) {
          changeCustomerPassword (currentPassword: $currentPassword, newPassword: $newPassword) {
            id
            email
          }
        }
      `,
      variables: {
        currentPassword,
        newPassword,
      },
    });
    return data;
  },
  createPassword: async (newPassword) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation createPassword ($newPassword: String!) {
          createPassword (newPassword: $newPassword) {
            result
          }
        }
      `,
      variables: {
        newPassword,
      },
    });
    return data;
  },
  checkPhoneNumberOrEmailExist: async (account) => {
    const value = account?.replaceAll('-', '').replaceAll(' ', '')?.replaceAll('+', '');

    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation checkPhoneNumberOrEmailExist ($value: String!) {
          checkPhoneNumberOrEmailExist (account: $value) {
            result
          }
        }
      `,
      variables: {
        value,
      },
    });
    return data;
  },
  getUpdateInfoOTP: async (phone) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation getUpdateInfoOTP ( $account: String!, $type: String!) {
          getUpdateInfoOTP (account: $account, type: $type) {
            OTPExisted
            OTPExpiredAfter
            code
          }
        }
      `,
      variables: {
        account: phone?.replaceAll('+', ''),
        type: 'change_phone',
      },
    });
    return data;
  },
  updateNormalInfo: async (value) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation updateCustomerV2 ($input: CustomerInputUpdate!) {
          updateCustomerV2 (input: $input) {
            result
          }
        }
      `,
      variables: {
        input: value,
      },
    });
    return data;
  },
  updateCustomerAddress: async (id, value) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation updateCustomerAddress ($id: Int!, $input: CustomerAddressInput!) {
          updateCustomerAddress (id: $id, input: $input) {
            id
            city
            postcode
          }
        }
      `,
      variables: {
        input: {
          ...value,
          city: value?.city || 0,
        },
        id,
      },
    });
    return data;
  },
  deleteAccount: async (password) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation requestDeleteAccount ($password: String!) {
          requestDeleteAccount (password: $password) {
            result
          }
        }
      `,
      variables: {
        password,
      },
    });
    return data;
  },
  signOut: async () => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation revokeCustomerToken {
          revokeCustomerToken {
            result
          }
        }
      `,
    });
    return data;
  },
  changeCustomerInfo: async (account, otpCode) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation changeCustomerInfo ($account: String!, $otpCode: String!) {
          changeCustomerInfo (account: $account, otpCode: $otpCode) {
            email
            phone_number
          }
        }
      `,
      variables: {
        account,
        otpCode,
      },
    });
    return data;
  },
  getUserInformation: async (token, customer_id, url_key) => {
    if (token) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    }
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
      query customerProfile ($customerId: String, $url_key: String) {
        customerProfile (customerId: $customerId, url_key: $url_key) {
          id
          avatar
          cover_image
          firstname
          lastname
          authentication_status
          account_type
          description
          phone_number
          secondary_phone_number
          email
          secondary_mail
          location
          is_salon_owner
          is_verify_email
          is_verify_second_email
          is_verify_second_phone
          salon_owner_status
          receive_sms
          receive_email
          social {
            social_customer_id
            social_id
            username
            type
            email
          }
          setup_password_yet
          certificates {
              file
              link
              review_status
          }
          addresses {
            id
            city
            country_code
            street
            postcode
            region {
                region
                region_code
                region_id
            }
          }
          seller_id
          url_key
          result_vote {
        id
        vote_id
        vote_name
        url_key
        position
        total_vote
    }
        }
      }
      `,
      variables: {
        customerId: customer_id || '',
        url_key: url_key || '',
      },
    });
    if (data?.data?.customerProfile?.avatar) {
      data.data.customerProfile.avatar = await imageValidate.checkSimpleUrl(
        data?.data?.customerProfile?.avatar,
        defaultAvatar
      );
    }
    if (data?.data?.customerProfile?.certificates) {
      data.data.customerProfile.certificates = await imageValidate.checkMutipleUrl(
        data?.data?.customerProfile?.certificates,
        'link',
        defaultAvatar
      );
    }
    if (data?.data?.customerProfile?.addresses && data?.data?.customerProfile?.addresses?.city === '0') {
      data.data.customerProfile.addresses.city = undefined;
    }
    return data;
  },
  getEmailByPhone: async (phone_number) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        query getInfoByPhoneNumber ($phone_number: String) {
          getInfoByPhoneNumber (phone_number: $phone_number) {
            email
          }
        }
      `,
      variables: {
        phone_number: phone_number || 0,
      },
    });

    return data;
  },
  uploadAvatar: async (avatar, address) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation updateCustomerV2 ($input: CustomerInputUpdate!) {
          updateCustomerV2 (input: $input) {
            result
          }
        }
      `,
      variables: {
        input: {
          avatar,
        },
      },
    });
    return data;
  },
  uploadCoverImage: async (cover_image, address) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation updateCustomerV2 ($input: CustomerInputUpdate!) {
          updateCustomerV2 (input: $input) {
            result
          }
        }
      `,
      variables: {
        input: {
          cover_image,
          address,
        },
      },
    });
    return data;
  },
  requestSupportCustomer: async (type, values) => {
    const input = {
      type_request: type,
      files_requests:
        values?.files?.map((item) => ({
          name: item?.name?.replaceAll(' ', ''),
          base64_encoded_file: item?.thumbUrl,
        })) || [],
      data_request: {
        description: values?.description || '',
        firstname: values?.firstname,
        lastname: values?.lastname,
      },
    };
    if (values?.firstname && values?.lastname) {
      input.data_request = {
        ...input?.data_request,
        firstname: values?.firstname,
        lastname: values?.lastname,
      };
    }

    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation requestSupportCustomer ($input: RequestSupportInput!) {
          requestSupportCustomer (input: $input) {
            result
          }
        }
      `,
      variables: {
        input,
      },
    });
    return data;
  },
  addSalon: async (value, accountInfo) => {
    const statesLocal = parseJSON(localStorage.getItem('states') || '[]');
    let region = {};
    statesLocal?.forEach((state) => {
      if (state?.name === value?.state) {
        region = {
          region: state?.name,
          region_id: state?.id,
          region_code: state?.code,
        };
      }
    });
    const input = {
      salon_information: [
        {
          name: value?.name,
          description: value?.about,
          address: {
            region,
            country_code: 'US',
            street: value?.address,
            postcode: value?.zip,
            city: value?.city,
          },
          gallery: value?.gallery?.fileList?.map((item) => ({
            name: item?.name?.replaceAll(' ', ''),
            base64_encoded_file: item?.base64_encoded_file,
          })),
          business_license: value?.business_license?.fileList?.map((item) => ({
            name: item?.name?.replaceAll(' ', ''),
            base64_encoded_file: item?.base64_encoded_file,
          })),
        },
      ],
    };
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation updateCustomerV2 ($input: CustomerInputUpdate!) {
          updateCustomerV2 (input: $input) {
            result
          }
        }
      `,
      variables: {
        input,
      },
    });
    return data;
  },
  createPostFeed: async (input) => {
    input.description = input?.description?.replaceAll('<p>', '')?.replaceAll('</p>', '\r\n');
    input.images = input?.images?.map((item) => ({
      base64_encoded_file: item?.base64_encoded_file,
      name: item?.name?.replaceAll(' ', '') || 'image.png',
    }));
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation createPostFeed($input: PostCreateInput!) {
          createPostFeed(input: $input) {
            result
          }
        }
      `,
      variables: {
        input,
      },
    });
    return data;
  },
  getPostFeedList: async (customerId, pageSize, currentPage, privacy) => {
    const privacyParam = privacy ? `$privacy: String` : ``;
    const privacyFilter = privacy ? `privacy: {eq: $privacy}` : ``;
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        query getFeedList($customerId: String, $pageSize: Int, $curentPage: Int, ${privacyParam}) {
          getFeedList (
            pageSize: $pageSize,
            filter: {
              customer_id: $customerId,
              ${privacyFilter}
              status: {
                eq: approved
              }
            }
            sort: [
                { created_at: DESC }
            ],
            currentPage: $curentPage
            ) {
              items {
                  feed_id
                  status
                  type
                  content
                  privacy
                  link
                  link_title
                  link_description
                  link_thumbnail
                  is_pinned
                  customer_id
                  total_like
                  total_comment
                  background
                  font
                  created_at
                  media_gallery {
                      media_type
                      image_url
                      video_url
                      path
                      id,
                      video_thumbnail
                  }
                  customer {
                    avatar
                    firstname
                    lastname
                  }
                  products {
                    id
                    sku
                    name
                    price
                    qty
                    description
                    image {
                        name
                        url
                    }
                    __typename
                  }
                }
            }
        }
      `,
      variables: {
        pageSize: pageSize || 10,
        customerId,
        curentPage: currentPage || 1,
        privacy,
      },
    });

    const items = data?.data?.getFeedList?.items;
    for (let i = 0; i < items?.length; i++) {
      if (data?.data?.getFeedList?.items[i]?.customer) {
        data.data.getFeedList.items[i].customer.avatar = await imageValidate.checkSimpleUrl(
          items[i]?.customer?.avatar,
          defaultAvatar
        );
      }
    }
    return data;
  },
  getPostDetail: async (id) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        query feedDetail($id: String!) {
          feedDetail(id: $id) {
            feed_id
            status
            type
            content
            privacy
            is_pinned
            customer_id
            total_like
            total_comment
            created_at
            link
            link_title
            link_description
            background
            font
            link_thumbnail
            customer {
                avatar
                firstname
                lastname
                url_key
                id
            }
            media_gallery {
                path
                media_type
                video_url
                video_thumbnail
                image_base64
                image_url
                id
            }
            products {
              id
              trigger_time
              sku
              name
              price
              qty
              description
              image {
                  name
                  url
              }
              __typename
            }
          }
        }
      `,
      variables: {
        id,
      },
    });

    return data;
  },
  getPrivacyFeed: async (id) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        query feedDetail($id: String!) {
          feedDetail(id: $id) {
            privacy
            customer_id
          }
        }
      `,
      variables: {
        id,
      },
    });

    return data;
  },
  getPostStatus: async (id) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        query feedDetail($id: String!) {
          feedDetail(id: $id) {
            status
            customer {
              id
            }
          }
        }
      `,
      variables: {
        id,
      },
    });

    return data;
  },
  DeletePostFeed: async (id) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation deleteFeed($id: String) {
          deleteFeed(id: $id) {
              result
          }
        }
      `,
      variables: {
        id,
      },
    });
    return data;
  },
  EditPostFeed: async (input) => {
    let description = input?.description;
    description = description?.replaceAll('<pre>', '')?.replaceAll('</pre>', '');
    description = description?.replaceAll('<code class="language-javascript">', '')?.replaceAll('</code>', '');
    description = description?.replaceAll('<br>', '\r\n ');
    input.description = description;
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation editPostFeed($input: PostEditInput!) {
            editPostFeed(input: $input) {
              result
            }
        }
      `,
      variables: {
        input,
      },
    });
    return data;
  },
  getOTPUpdateSecondaryInfo: async (account, type) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation updateSecondaryInfo ( $account: String!, $type: String!) {
          updateSecondaryInfo (account: $account, type: $type) {
            OTPExisted
            OTPExpiredAfter
            code
          }
        }
      `,
      variables: {
        account: account?.replaceAll('+', ''),
        type,
      },
    });
    return data;
  },
  verifyUpdateSecondaryInfo: async (otpCode, account, type) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation verifyUpdateSecondaryInfo ($account: String!, $otpCode: String!, $type: String!) {
          verifyUpdateSecondaryInfo (account: $account, otpCode: $otpCode, type: $type) {
            result
          }
        }
      `,
      variables: {
        account: account?.replaceAll('+', ''),
        otpCode,
        type,
      },
    });
    return data;
  },
  UpdatePrimaryEmail: async (email, password) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation changePrimaryEmail($email: String!, $password: String!) {
          changePrimaryEmail(email: $email, password: $password) {
            result
          }
        }
      `,
      variables: {
        email,
        password,
      },
    });
    return data;
  },
  VerifyPrimaryEmail: async (otpCode) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation verifyPrimaryEmail($otpCode: String!) {
          verifyPrimaryEmail(otpCode: $otpCode) {
            result
          }
        }
      `,
      variables: {
        otpCode,
      },
    });
    return data;
  },
  getComments: async (feedId, pageSize, currentPage) => {
    return axios.post(ProfileService.endpoint, {
      query: `
        query getCommentFeed($feedId: String!, $pageSize: Int, $currentPage: Int) {
          getCommentFeed(feedId: $feedId, pageSize: $pageSize, currentPage: $currentPage) {
            page_info {
              current_page
              page_size
              total_page
            }
            items {
              comment_id
              status
              comment
              firstname
              lastname
              avatar
              parent_comment_id
              customer_id
              url_key
              admin_id
              created_at
              updated_at
              comments_child {
                comment_id
                status
                comment
                parent_comment_id
                customer_id
                firstname
                lastname
                avatar
                admin_id
                created_at
                updated_at
                url_key
            }
            }
          }
        }
      `,
      variables: {
        feedId,
        pageSize: pageSize || 10,
        currentPage: currentPage || 1,
      },
    });
  },
  getChildComments: async (commentParentId, pageSize, currentPage) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        query getChildComments($commentParentId: String!, $pageSize: Int, $currentPage: Int) {
          getChildComments(commentParentId: $commentParentId, pageSize: $pageSize, currentPage: $currentPage) {
            page_info {
              current_page
              page_size
              total_page
            }
            items {
              comment_id
              status
              comment
              firstname
              lastname
              avatar
              parent_comment_id
              customer_id
              admin_id
              created_at
              updated_at
              url_key
            }
          }
        }
      `,
      variables: {
        commentParentId,
        pageSize: pageSize || 10,
        currentPage: currentPage || 1,
      },
    });
    return data;
  },
  createComment: async (input) => {
    return axios.post(ProfileService.endpoint, {
      query: `
        mutation createCommentFeed($input: CommentFeedInput!) {
          createCommentFeed(input: $input) {
            status
            item {
                comment_id
                status
                comment
                parent_comment_id
                customer_id
                admin_id
                created_at
                updated_at
            }
          }
        }
      `,
      variables: {
        input,
      },
    });
  },
  editComment: async (input) => {
    return axios.post(ProfileService.endpoint, {
      query: `
        mutation editCommentFeed($input: EditCommentFeedInput!) {
          editCommentFeed(input: $input) {
            item {
              comment_id
              status
              comment
              parent_comment_id
              customer_id
              admin_id
              created_at
              updated_at
          }
          }
        }
      `,
      variables: {
        input,
      },
    });
  },
  getCommentById: async (commemtId) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        query getCommentById($commemtId: String) {
          getCommentById (id: $commemtId )
          {
            comment_id
            status
            comment
            parent_comment_id
            customer_id
            firstname
            lastname
            avatar
            admin_id
            created_at
            updated_at
            url_key
            comments_child {
                comment_id
                status
                comment
                parent_comment_id
                customer_id
                firstname
                lastname
                avatar
                admin_id
                total_reaction
                created_at
                updated_at,
                url_key
            }
            }
          }
      `,
      variables: {
        commemtId,
      },
    });
    return data;
  },
  getCustomerMedia: async (customerId, pageSize, curentPage, type) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        query getCustomerMedia($customerId: String, $pageSize: Int, $curentPage: Int, $type: String!) {
          getCustomerMedia (
            pageSize: $pageSize,
            filter: {
              customer_id: $customerId,
              type: $type
            }
            currentPage: $curentPage
            ) {
              items {
                media_type
                path
                video_url
                feed_id
                content
                created_at
                short_time
                customer_id
                customer {
                    email
                    firstname
                    lastname
                    avatar
                }
              }
              page_info {
                  current_page,
                  page_size,
                  total_page
              }
            }
          }
      `,
      variables: {
        pageSize,
        customerId,
        curentPage,
        type,
      },
    });
    return data;
  },
  follow: async (id) => {
    return axios.post(ProfileService?.endpoint, {
      query: `
        mutation followCustomer($idFollowCustomer: String!) {
          followCustomer(idFollowCustomer: $idFollowCustomer) {
            result
          }
        }
      `,
      variables: {
        idFollowCustomer: id,
      },
    });
  },
  unfollow: async (id) => {
    return axios.post(ProfileService?.endpoint, {
      query: `
        mutation unFollowCustomer($idUnfollowCustomer: String!) {
          unFollowCustomer(idUnfollowCustomer: $idUnfollowCustomer) {
            result
          }
        }
      `,
      variables: {
        idUnfollowCustomer: id,
      },
    });
  },
  removeFollower: async (id) => {
    return axios.post(ProfileService?.endpoint, {
      query: `
        mutation removeFollower($idFollower: String!) {
          removeFollower(idFollower: $idFollower) {
            result
          }
        }
      `,
      variables: {
        idFollower: id,
      },
    });
  },
  deleteComment: async (id) => {
    return axios.post(ProfileService.endpoint, {
      query: `
        mutation deleteComment($id: String!) {
          deleteComment(id: $id) {
            result
          }
        }
      `,
      variables: {
        id,
      },
    });
  },

  reactionComment: async (id, type) => {
    return axios.post(ProfileService.endpoint, {
      query: `
        mutation reactionComment($comment_id: Int!, $type: Int!) {
          reactionComment(comment_id: $comment_id, type: $type) {
            result
          }
        }
      `,
      variables: {
        comment_id: id,
        type,
      },
    });
  },
  unReactionComment: async (id) => {
    return axios.post(ProfileService.endpoint, {
      query: `
        mutation unReactionComment($comment_id: Int!) {
          unReactionComment(comment_id: $comment_id) {
            result
          }
        }
      `,
      variables: {
        comment_id: id,
      },
    });
  },
  reactionFeed: async (id, type) => {
    return axios.post(ProfileService.endpoint, {
      query: `
        mutation reactionFeed($feed_id: Int!, $type: Int!) {
          reactionFeed(feed_id: $feed_id, type: $type) {
            result
          }
        }
      `,
      variables: {
        feed_id: id,
        type,
      },
    });
  },
  updateReactionFeed: async (id, type, type_removed) => {
    return axios.post(ProfileService.endpoint, {
      query: `
        mutation updateReactionFeed($feed_id: Int!, $type: Int!, $type_removed: Int!) {
          updateReactionFeed(feed_id: $feed_id, type: $type, type_removed: $type_removed) {
            result
          }
        }
      `,
      variables: {
        feed_id: id,
        type,
        type_removed,
      },
    });
  },
  unReactionFeed: async (id, type) => {
    return axios.post(ProfileService.endpoint, {
      query: `
        mutation unReactionFeed($feed_id: Int!, $type: Int!) {
          unReactionFeed(feed_id: $feed_id, type: $type) {
            result
          }
        }
      `,
      variables: {
        feed_id: id,
        type,
      },
    });
  },
  updateStatusReceiveNotifcations: async (field, status) => {
    return axios.post(ProfileService?.endpoint, {
      query: `
          mutation updateStatusReceiveNotifcations($field: String!, $status: Int!) {
            updateStatusReceiveNotifcations(
              field: $field,
              status: $status
            ) {
            result
          }
        }
      `,
      variables: {
        field,
        status,
      },
    });
  },
  updatePrivacy: async (id, privacy) => {
    return axios.post(ProfileService.endpoint, {
      query: `
        mutation updatePrivacy($feed_id: Int!, $privacy: String!) {
          updatePrivacy(feed_id: $feed_id, privacy: $privacy) {
            result
          }
        }
      `,
      variables: {
        feed_id: id,
        privacy,
      },
    });
  },
  getListRequestByCustomer: async () => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        {
          getListRequestByCustomer {
              items {
                  entity_id
                  topic_id
                  status
                  value_update
                  created_at
                  comment
              }
          }
        }
      `,
    });
    return data;
  },
  getOTPDeleteAccount: async (phoneNumber) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation getOTPDeleteAccount ($phoneNumber: String!) {
          getOTPDeleteAccount (phoneNumber: $phoneNumber) {
            OTPExisted
            OTPExpiredAfter
            code
          }
        }
      `,
      variables: {
        phoneNumber,
      },
    });
    return data;
  },
  requestDeleteAccount: async (phoneNumber, otpCode, password) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        mutation requestDeleteAccount ($phoneNumber: String!, $otpCode: String!, $password: String!) {
          requestDeleteAccount (phoneNumber: $phoneNumber, otpCode: $otpCode, password: $password) {
            result
          }
        }
      `,
      variables: {
        phoneNumber,
        otpCode,
        password,
      },
    });
    return data;
  },
  getInfoLink: async (link) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        query getInfoLink ($link: String!) {
          getInfoLink (link: $link) {
            title
            description
            thumbnail
          }
        }
      `,
      variables: {
        link,
      },
    });
    return data;
  },
  getAvatarByCustomer: async (customer_id) => {
    const { data } = await axios.post(ProfileService.endpoint, {
      query: `
        query customerProfile ($customerId: String) {
          customerProfile (customerId: $customerId) {
            avatar
          }
        }
        `,
      variables: {
        customerId: customer_id || '',
      },
    });
    return data;
  },
};

export default ProfileService;
