import axios from 'axios';

const ResumeService = {
    endpoint: process.env.REACT_APP_URL_API,
    getResumeSkill: async () => {
        const { data } = await axios.post(ResumeService.endpoint, {
            query: `
                query {
                    getSkillResumeAvailable {
                        label
                    }
                }
            `,
        });
        return data;
    },
    createResume: async (input) => {
        const { data } = await axios.post(ResumeService.endpoint, {
            query: `
                mutation createResume($input: ResumeCreateInput!) {
                    createResume(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        });
        return data;
    },
    getResume: async (customerId) => {
        const { data } = await axios.post(ResumeService.endpoint, {
            query: `
                query getResume  ($customerId: String) {
                        getResume(customerId: $customerId) {
                        id 
                        firstname
                        lastname
                        country_code
                        phone_number
                        address
                        avatar
                        email
                        profile_summary
                        pdf_file
                        pdf_id
                        skill {
                            id
                            skill
                            level
                        }
                        social_link {
                            id
                            label
                            link
                        }
                        experience {
                            id
                            job_title
                            employer
                            start_date
                            end_date
                            city
                            description
                        }
                        certificates {
                            id
                            url
                        }
                        art {
                            id
                            url
                        }
                    }
                }
            `,
            variables: { customerId },
        });
        return data;
    },
};

export default ResumeService;
