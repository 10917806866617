const imageValidate = {
    checkUrlNotFound: async (url) => {
        if (!url) return false;
        return new Promise((resolve) => {
            const imgEl = document.createElement('img');
            imgEl.src = url + '';
            imgEl.onload = () => resolve(true);
            imgEl.onerror = () => resolve(false);
        });
    },
    checkMutipleUrl: async (values, keyName, url_default) => {
        const result = [];
        for (const value of values) {
            const check = await imageValidate.checkUrlNotFound(value[keyName]);
            if (!check) {
                value[keyName] = url_default;
            }
            result.push(value);
        }
        return result;
    },

    checkSimpleUrl: async (url, url_default) => {
        if (!url) return url;
        return (await imageValidate.checkUrlNotFound(url)) ? url : url_default;
    },
    getImageFormatFromUrl: (url) => {
        const match = url.match(/\.(jpg|jpeg|png|gif|bmp|webp|svg|ico|raw)$/i);

        if (match) {
            return match[0].slice(1).toLowerCase();
        } else {
            throw new Error('Unknown');
        }
    },
};
export default imageValidate;
