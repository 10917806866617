import { Duration, DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

const checkOngoing = (to) => {
    if (typeof to !== 'number') {
        return false;
    }
    const now = DateTime.now();
    const end = DateTime.fromSeconds(to); // Convert timestamp to DateTime object
    const remainingDuration = end.diff(now); // Calculate remaining time
    const isRunning = remainingDuration.toMillis() > 0;
    return isRunning
}

export default checkOngoing