import axios from 'axios';
import imageValidate from 'utils/imageValidate';
import defaultAvatar from 'assets/images/avatar.webp';
import { keyUser } from 'variable';
import { getAddress, getPersonalAddress, getRegion, getSalonInfomation, parseJSON } from 'utils';

const formatPhone = (phone) => {
    phone = phone?.replaceAll(' ', '')?.replaceAll('-', '');
    if (phone?.includes('+840')) {
        return phone?.replace('+840', '84');
    }
    if (phone?.includes('+84')) {
        return phone?.replace('+', '');
    }
    return phone?.replace('+', '');
};

const LoginService = {
    endpoint: process.env.REACT_APP_URL_API,
    checkPhoneNumberOrEmailExist: async (account) => {
        const value = account?.replaceAll('-', '').replaceAll(' ', '')?.replaceAll('+', '');

        const { data } = await axios.post(LoginService.endpoint, {
            query: `
        mutation checkPhoneNumberOrEmailExist ($value: String!) {
          checkPhoneNumberOrEmailExist (account: $value) {
            result
            secondary_phone_number
          }
        }
      `,
            variables: {
                value,
            },
        });
        return data;
    },
    getRegisterOTP: async (phoneNumber, channel) => {
        const { data } = await axios.post(LoginService.endpoint, {
            query: `
        mutation getRegisterOTP ($phoneNumber: String!, $channel: String!) {
          getRegisterOTP (phoneNumber: $phoneNumber, channel: $channel) {
            result
          }
        }
      `,
            variables: {
                phoneNumber: formatPhone(phoneNumber),
                channel: channel || 'sms',
            },
        });
        return data;
    },
    getOtpSocialLogin: async (phoneNumber) => {
        const { data } = await axios.post(LoginService.endpoint, {
            query: `
        mutation getOTPSocialLogin ($phoneNumber: String!) {
          getOTPSocialLogin (phoneNumber: $phoneNumber) {
            OTPExisted
            OTPExpiredAfter
            code
          }
        }
      `,
            variables: {
                phoneNumber: phoneNumber?.replaceAll('-', '').replaceAll(' ', '')?.replaceAll('+', ''),
            },
        });
        return data;
    },
    signwithPassword: async (phoneNumber, password, tokenCaptcha) => {
        const { data } = await axios.post(
            LoginService.endpoint,
            {
                query: `
                mutation generateCustomerToken ($email: String!, $password: String!) {
                  generateCustomerToken (email: $email, password: $password) {
                    token
                    lock_expired
                  }
                }
              `,
                variables: {
                    email: phoneNumber?.replaceAll('-', '')?.replaceAll(' ', '')?.replaceAll('+', ''),
                    password,
                },
            },
            {
                headers: {
                    'X-reCaptcha': tokenCaptcha,
                },
            }
        );
        return data;
    },
    getOTP: async (phone_number, type) => {
        const { data } = await axios.post(LoginService.endpoint, {
            query: `
              mutation getOTP ($account: String!, $type: String!) {
                getOTP (account: $account, type: $type) {
                  OTPExisted
                  OTPExpiredAfter
                  code
                }
              }
          `,
            variables: {
                account: phone_number?.replaceAll('-', '').replaceAll(' ', '')?.replaceAll('+', ''),
                type: type || 'register',
            },
        });
        return data;
    },
    createCustomer: async (account, otpCode, tokenRecaptcha, token) => {
        let email = account?.email;
        if (account?.email) {
            email = email + '';
            email = email?.toString()?.trim()?.toLowerCase();
        }

        const input = {
            firstname: account?.firstname,
            lastname: account?.lastname,
            email: email?.length === 0 ? undefined : email,
            password: account?.pin,
            is_complete_register: true,
            is_register_step: true,
        };

        axios.defaults.headers.common.Authorization = 'Bearer ' + token;
        const { data } = await axios.post(
            LoginService.endpoint,
            {
                query: `
        mutation updateCustomerV2 ($input: CustomerInputUpdate!) {
          updateCustomerV2(input: $input) {
            result
          }
        }
      `,
                variables: {
                    input,
                },
            },
            {
                headers: {
                    'X-reCaptcha': tokenRecaptcha,
                },
            }
        );
        return data;
    },
    updateCustomer: async (profile, is_register_step, addressSuccess = true, salonSuccess = true) => {
        const user = parseJSON(localStorage.getItem(keyUser));

        const salonAddress = getAddress(profile?.country_code, profile?.address, profile?.zip, profile?.city);
        const persoalAddress = getAddress(profile?.country_code, profile?._address, profile?._zip, profile?._city);
        const personalRegion = getRegion(profile?._state);

        if (Object.keys(personalRegion)?.length) {
            persoalAddress.region = personalRegion;
        }

        const input = {
            description: profile?.description,
            certificates:
                profile?.certificates?.map((item) => ({
                    name: item?.name.replaceAll(' ', ''),
                    base64_encoded_file: item?.base64_encoded_file || item?.thumbUrl,
                })) || [],
        };

        if (profile?._zip && addressSuccess) {
            input.address = getPersonalAddress(user, profile, persoalAddress);
        }

        if (profile?.avatar && profile?.avatar?.fileList[0]) {
            input.avatar = {
                name: profile?.avatar?.file?.name.replaceAll(' ', ''),
                base64_encoded_file: profile?.avatar?.fileList[0]?.base64_encoded_file,
            };
        }

        if (profile?.name && salonSuccess) {
            input.salon_information = getSalonInfomation(profile, salonAddress);
        }

        if (profile?.secondary_mail) {
            input.secondary_mail = profile?.secondary_mail;
        }

        if (profile?.secondary_phone_number) {
            input.secondary_phone_number =
                profile?.secondary_phone_number?.replaceAll('-', '')?.replaceAll(' ', '')?.replaceAll('+', '') || '';
        }

        if (is_register_step) {
            input.is_register_step = is_register_step;
        }

        const { data } = await axios.post(LoginService.endpoint, {
            query: `
        mutation updateCustomerV2 ($input: CustomerInputUpdate!) {
          updateCustomerV2 (input: $input) {
            result
          }
        }
      `,
            variables: {
                input,
            },
        });
        return data;
    },
    getCustomer: async (token) => {
        axios.defaults.headers.common.Authorization = 'Bearer ' + token;
        const { data } = await axios.post(LoginService.endpoint, {
            query: `
        query customer {
          customer {
            firstname
            lastname
          }
        }
      `,
        });
        return data?.data?.customer;
    },
    updatePassword: async (phoneNumber, newPassword, otpCode, tokenRecaptcha, isSecondPhone) => {
        const { data } = await axios.post(
            LoginService.endpoint,
            {
                query: `
        mutation resetNewPassword ($account: String!, $newPassword: String!, $otpCode: String!, $isSecondPhone: Boolean!) {
          resetNewPassword(account: $account, newPassword: $newPassword, otpCode: $otpCode, isSecondPhone: $isSecondPhone)
        }
      `,
                variables: {
                    account: phoneNumber?.replaceAll('-', '').replaceAll(' ', '')?.replaceAll('+', ''),
                    newPassword,
                    otpCode,
                    isSecondPhone,
                },
            },
            {
                headers: {
                    'X-Recaptcha': tokenRecaptcha,
                },
            }
        );
        return data;
    },
    signOut: async () => {
        const { data } = await axios.post(LoginService.endpoint, {
            query: `
        mutation revokeCustomerToken {
          revokeCustomerToken {
            result
          }
        }
      `,
        });
        return data;
    },
    signInwithOTP: async (phoneNumber, otpCode) => {
        const { data } = await axios.post(LoginService.endpoint, {
            query: `
      mutation verifySigninOTP ($account: String!, $otpCode: String!) {
        verifySigninOTP (account: $account, otpCode: $otpCode) {
          token
          lock_expired
        }
      }
      `,
            variables: {
                account: phoneNumber?.replaceAll('-', '').replaceAll(' ', '')?.replaceAll('+', ''),
                otpCode,
            },
        });
        return data;
    },
    verifyOTP: async (account, otpCode, type) => {
        const { data } = await axios.post(LoginService.endpoint, {
            query: `
        mutation verifyOTP ($account: String!, $otpCode: String!, $type: String!) {
          verifyOTP (account: $account, otpCode: $otpCode, type: $type) {
            result
            token
          }
        }
      `,
            variables: {
                account: account?.replaceAll('-', '')?.replaceAll(' ', '')?.replaceAll('+', ''),
                otpCode,
                type,
            },
        });
        return data;
    },
    getUserInformation: async (token) => {
        if (token) {
            axios.defaults.headers.common.Authorization = 'Bearer ' + token;
        }
        const { data } = await axios.post(LoginService.endpoint, {
            query: `
      query customerProfile ($customerId: String) {
        customerProfile (customerId: $customerId) {
          id
          cover_image
          avatar
          firstname
          lastname
          authentication_status
          account_type
          description
          phone_number
          secondary_phone_number
          email
          secondary_mail
          location
          is_salon_owner
          is_verify_email
          salon_owner_status
          receive_sms
          receive_email
          social {
            social_customer_id
            social_id
            username
            type
            email
          }
          setup_password_yet
          certificates {
              file
              link
              review_status
          }
          addresses {
            id
            city
            country_code
            street
            postcode
            region {
                region
                region_code
                region_id
            }
          }
          seller_id
          url_key
        }
      }
      `,
            variables: {
                customerId: '',
            },
        });
        if (data?.data?.customerProfile?.avatar) {
            data.data.customerProfile.avatar = await imageValidate.checkSimpleUrl(
                data?.data?.customerProfile?.avatar,
                defaultAvatar
            );
        }
        if (data?.data?.customerProfile?.certificates) {
            data.data.customerProfile.certificates = await imageValidate.checkMutipleUrl(
                data?.data?.customerProfile?.certificates,
                'link',
                defaultAvatar
            );
        }
        return data;
    },
    skipCreateProfile: async () => {
        return axios.post(LoginService.endpoint, {
            query: `
        mutation {
          skipStep3SignUp {
            result
          }
        }
      `,
        });
    },
    createProfile: async ({ input, otpCode, isSkip, tokenRecaptcha, fillEnoughAddress, fillEnoughSalon }) => {
        const getState = (stateName) => {
            const states = parseJSON(localStorage.getItem('states')) || [];
            const result = {};
            states?.forEach((item) => {
                if (item?.name === stateName) {
                    result.region = stateName;
                    result.region_code = item?.code;
                    result.region_id = item?.id;
                }
            });
            return result;
        };

        const address = {
            firstname: input?.firstname,
            lastname: input?.lastname,
            telephone: input?.phoneNumber?.replaceAll('-', '').replaceAll(' ', '')?.replaceAll('+', ''),
            region: getState(input?._state?.name),
            country_code: 'US',
            street: input?._address,
            postcode: input?._zip,
            city: input?._city?.name,
        };

        const salonAddress = {
            region: getState(input?.state),
            country_code: 'US',
            street: input?.address,
            postcode: input?.zip,
            city: input?.city,
            lat: input?.lat,
            lng: input?.lng,
        };

        const salon_information = {
            id: null,
            name: input?.name,
            description: input?.about,
            address: salonAddress,
            gallery: input?.gallery?.map((item) => ({
                name: item?.name?.replaceAll(' ', ''),
                base64_encoded_file: item?.thumbUrl || item?.base64_encoded_file,
            })),
            business_license: input?.business_license?.map((item) => ({
                name: item?.name?.replaceAll(' ', ''),
                base64_encoded_file: item?.thumbUrl || item?.base64_encoded_file,
            })),
        };

        const values = {
            firstname: input?.firstname,
            lastname: input?.lastname,
            account_type: input?.account_type,
            is_salon_owner: fillEnoughSalon ? 1 : 0,
            address: fillEnoughAddress ? address : undefined,
            description: input?.description,
            is_register_step: true,
            avatar: input?.avatar || undefined,
            certificates: input?.certificates?.map((item) => ({
                name: item?.name?.replaceAll(' ', ''),
                base64_encoded_file: item?.thumbUrl || item?.base64_encoded_file,
            })),
            salon_information: fillEnoughSalon ? [salon_information] : undefined,
        };

        const { data } = await axios.post(
            LoginService.endpoint,
            {
                query: `
        mutation updateCustomerV2($input: CustomerInputUpdate!) {
          updateCustomerV2(input: $input) {
            result
          }
        }
      `,
                variables: {
                    input: values,
                },
            },
            {
                headers: {
                    'X-reCaptcha': tokenRecaptcha,
                },
            }
        );
        return data;
    },

    checkSocialLoginToken: async (token, type) => {
        return axios.post(LoginService.endpoint, {
            query: `
        mutation checkSocialLoginToken($token: String!, $type: String!) {
            checkSocialLoginToken(token: $token, type: $type){
              result
              token
            }
        }
      `,
            variables: {
                token,
                type,
            },
        });
    },

    checkSocialLoginTwitter: async (token, type, oauth_token, oauth_token_secret) => {
        return axios.post(LoginService.endpoint, {
            query: `
        mutation checkSocialLoginToken($token: String!, $type: String!, $oauth_token: String!, $oauth_token_secret: String!) {
            checkSocialLoginToken(token: $token, type: $type, oauth_token: $oauth_token, oauth_token_secret: $oauth_token_secret){
              result
              token
            }
        }
      `,
            variables: {
                token,
                type,
                oauth_token,
                oauth_token_secret,
            },
        });
    },

    mapSocialLogin: async (token, type, phone_number, otpCode) => {
        return axios.post(LoginService.endpoint, {
            query: `
        mutation mapSocialLogin($token: String!, $type: String!, $phone_number: String!, $otpCode: String!) {
            mapSocialLogin(token: $token, type: $type, phone_number: $phone_number, otpCode: $otpCode){
              result
              token
            }
        }
      `,
            variables: {
                token,
                type,
                phone_number,
                otpCode,
            },
        });
    },
    mapSocialLoginTwitter: async (token, type, phone_number, otpCode, oauth_token, oauth_token_secret) => {
        return axios.post(LoginService.endpoint, {
            query: `
        mutation mapSocialLogin($token: String!, $type: String!, $phone_number: String!, $otpCode: String!, $oauth_token: String!, $oauth_token_secret: String!) {
            mapSocialLogin(token: $token, type: $type, phone_number: $phone_number, otpCode: $otpCode, oauth_token: $oauth_token, oauth_token_secret: $oauth_token_secret){
              result
              token
            }
        }
      `,
            variables: {
                token,
                type,
                phone_number,
                otpCode,
                oauth_token,
                oauth_token_secret,
            },
        });
    },

    deleteSocialLink: async (socialCustomerId) => {
        return axios.post(LoginService.endpoint, {
            query: `
        mutation deleteSocialLink($socialCustomerId: Int!) {
            deleteSocialLink(socialCustomerId: $socialCustomerId)
        }
      `,
            variables: {
                socialCustomerId,
            },
        });
    },
    getOptUpdateInfomationSocialAccount: async () => {
        return axios.post(LoginService.endpoint, {
            query: `
      mutation getUpdateCustomerOTP {
          getUpdateCustomerOTP {
            OTPExisted
            OTPExpiredAfter
            code
          }
        }
      `,
        });
    },
    createAccountForGuest: async (input, tokenRecaptcha) => {
        const { data } = await axios.post(
            LoginService.endpoint,
            {
                query: `
        mutation createCustomerV2($input: CustomerCreateInput!) {
          createCustomerV2(input: $input) {
            customer {
              firstname
              lastname
              email
            }
            token
          }
        }
      `,
                variables: { input },
            },
            {
                headers: {
                    'X-Recaptcha': tokenRecaptcha,
                },
            }
        );
        return data?.data;
    },
    sendOTPToSecondPhone: async (account, isSecondPhone) => {
        const { data } = await axios.post(LoginService.endpoint, {
            query: `
        mutation getOTPResetPassword($account: String!, $isSecondPhone: Boolean!) {
          getOTPResetPassword(account: $account, isSecondPhone: $isSecondPhone) {
            OTPExisted
            OTPExpiredAfter
            code
            result
          }
        }
      `,
            variables: {
                account: account?.replace('+', '')?.replaceAll(' ', '')?.replaceAll('-', ''),
                isSecondPhone,
            },
        });
        return data;
    },
};

export default LoginService;
