import AdsService from './ads';
import NotiService from './noti';
import EventService from './event';
import SalonService from './salon';
import LoginService from './login';
import SearchService from './search';
import ProfileService from './profile';
import FeedBackService from './feedback';
import MetaDataService from './meta-data';
import ReportService from './report';
import ResumeService from './resume';

import axios from 'axios';
export {
    AdsService,
    NotiService,
    LoginService,
    SalonService,
    EventService,
    SearchService,
    ProfileService,
    MetaDataService,
    FeedBackService,
    ReportService,
    ResumeService,
};

export const handleGraphQLGet = async ({
    schema = {
        query: '',
        name: ''
    },
}) => {
    const endPoint = process.env.REACT_APP_URL_API
    try {
        const { data } = await axios.post(endPoint, {
            query: schema.query,
        })
        if (data?.errors) throw data.errors[0]
        return {
            ok: true,
            data: data.data[schema.name]
        }
    } catch (error) {
        return {
            ok: false,
            error: {
                message: error.message,
                nameQuery: schema.name
            }
        }
    }
}

export const handleGraphQLMutation = async ({
    schema = {
        query: '',
        name: ''
    },
    body = {}
}) => {
    const endPoint = process.env.REACT_APP_URL_API
    try {
        const { data } = await axios.post(endPoint,
            {
                query: schema.query,
                variables: body
            })
        if (data?.errors) throw data.errors[0]
        return {
            ok: true,
            data: data.data[schema.name]
        }
    } catch (error) {
        return {
            ok: false,
            error: {
                message: error.message,
                nameQuery: schema.name
            },

        }
    }
}