import axios from 'axios';

const NotiService = {
    endpoint: process.env.REACT_APP_URL_API,
    getIconType: async () => {
        const { data } = await axios.post(NotiService.endpoint, {
            query: `
                query {
                    getCategoryNotifications {
                        type
                        icon
                        icon_active
                    }
                }
            `,
        });
        return data;
    },
};

export default NotiService;
