import { useInfiniteQuery, useMutation, } from "react-query"
import CUSTOMER_SERVICE from "services/refactor/customer"
import { QUERY_KEYS } from "../queryKeys"
// ============================================================
// CUSTOMER QUERIES
// ============================================================
export const useGetCustomerProfileById = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            if (!body) {
                // get for self
                return CUSTOMER_SERVICE.getCustomerProfileById({
                    body: {
                        customerId: ''
                    }
                })
            } else {
                return CUSTOMER_SERVICE.getCustomerProfileById({ body })
            }
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useUploadCustomerV2 = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return CUSTOMER_SERVICE.uploadCustomerV2({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}


export const useGetCustomerMedia = ({ customerId = '', pageSize = 5, type }) => {
    return useInfiniteQuery({
        queryKey: [QUERY_KEYS.GET_CUSTOMER_MEDIA, customerId, type],
        queryFn: async ({ pageParam = 1 }) => {
            const response = await CUSTOMER_SERVICE.getCustomerMedia({
                body: {
                    type,
                    currentPage: pageParam,
                    customerId,
                    pageSize
                }
            });
            return response;
        },
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage && lastPage.data && lastPage.data.page_info) {
                const { current_page, total_page } = lastPage.data.page_info;
                if (current_page < total_page) {
                    return current_page + 1;
                }
            }
            return null;
        },
        onSuccess: (data) => {
            return data;
        },
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: false
    })
}