import { handleGraphQLGet, handleGraphQLMutation } from 'services';
import CUSTOMER_QUERYS from 'gql/customer';
import { keyToken } from 'variable';
import axios from 'axios';

const CUSTOMER_SERVICE = {
    getCustomerProfileById: ({ body }) => {
        const token = localStorage.getItem(keyToken) ? JSON.parse(localStorage.getItem(keyToken)) : null;
        if (token) {
            axios.defaults.headers.common.Authorization = 'Bearer ' + token;
        }
        return handleGraphQLMutation({
            schema: CUSTOMER_QUERYS.GET_CUSTOMER_PROFILE_BY_ID,
            body,
        })
    },
    uploadCustomerV2: ({ body }) => {
        return handleGraphQLMutation({
            schema: CUSTOMER_QUERYS.UPLOAD_CUSTOMER_V2,
            body
        })
    },
    getCustomerMedia: ({ body }) => {
        return handleGraphQLMutation({
            schema: CUSTOMER_QUERYS.GET_CUSTOMER_MEDIA,
            body
        })
    }

}

export default CUSTOMER_SERVICE