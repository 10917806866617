import FOLLOW_QUERY from 'gql/follow';
import { handleGraphQLMutation } from 'services';

const FOLLOW_SERVICE = {
    addFollower: ({ body }) => handleGraphQLMutation({
        schema: FOLLOW_QUERY.ADD_FOLLOWER,
        body
    }),
    unFollower: ({ body }) => handleGraphQLMutation({
        schema: FOLLOW_QUERY.UN_FOLLOWER,
        body
    }),
}

export default FOLLOW_SERVICE