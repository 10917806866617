import {
    gql
} from '@apollo/client'
import { extractQueryInfo } from 'gql'

const ADD_FOLLOW_QUERY = gql`
    mutation followCustomer($idFollowCustomer: String) {
        followCustomer(idFollowCustomer: $idFollowCustomer) {   
            result
        }
    }
`

const DELETE_FOLLOW_QUERY = gql`
    mutation unFollowCustomer($idUnfollowCustomer: String) {
        unFollowCustomer(idUnfollowCustomer: $idUnfollowCustomer) {   
            result
        }
    }

`

const FOLLOW_QUERY = {
    ADD_FOLLOWER: extractQueryInfo(ADD_FOLLOW_QUERY),
    UN_FOLLOWER: extractQueryInfo(DELETE_FOLLOW_QUERY)
}

export default FOLLOW_QUERY