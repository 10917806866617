// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-message {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    width: 100% !important
}
.ant-message-notice {
    width: 100% !important;
    padding: 0px !important;
    text-align: left !important
}
.ant-message-notice-content {
    width: 100% !important
}
.ant-message-custom-content {
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: flex-start !important
}
`, "",{"version":3,"sources":["webpack://./src/components/Message/index.less"],"names":[],"mappings":"AACI;IAAA,0BAAA;IAAA,mBAAA;IAAA,oBAAA;IAAA;AAAA;AAIA;IAAA,sBAAA;IAAA,uBAAA;IAAA;AAAA;AAIA;IAAA;AAAA;AAIA;IAAA,wBAAA;IAAA,sBAAA;IAAA,8BAAA;IAAA;AAAA","sourcesContent":[".ant-message {\n    @apply w-full fixed top-0 left-0 !important;\n}\n\n.ant-message-notice {\n    @apply w-full p-0 text-left !important;\n}\n\n.ant-message-notice-content {\n    @apply w-full !important;\n}\n\n.ant-message-custom-content {\n    @apply w-full flex items-center justify-start !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
