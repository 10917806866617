const share = async (url) => {
    const shareData = {
        url,
    };
    try {
        await navigator.share(shareData);
    } catch (err) {
        console.log(err);
    }
};
export default share;
