import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalLowNetwork = () => {
    const { t } = useTranslation();

    const closeModalLowNetword = () => {
        document.getElementById('low-network')?.classList?.add('hidden');
    };

    return (
        <div className="hidden w-screen h-screen fixed top-0 left-0 z-[99]" id="low-network">
            <div className="relative w-full h-full">
                <div className="absolute w-full h-full bg-black/40 z-10" onClick={closeModalLowNetword}></div>
                <div className="w-[90%] p-4 bg-white rounded-lg shadow-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
                    <h4 className="text-base font-bold text-pink-500 text-center w-full">
                        {t('components.general.Connection is unstable')}
                    </h4>
                    <p className="text-sm font-medium text-black my-4 text-center">
                        {t('components.general.Connection is unstable, please check your network again')}
                    </p>
                    <p
                        className="w-full bg-pink-500 text-white rounded-lg h-12 flex items-center justify-center font-bold text-sm"
                        onClick={closeModalLowNetword}
                    >
                        {t('components.general.Close')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ModalLowNetwork;
