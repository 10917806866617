import React from 'react';
import MetaDataService from 'services/meta-data';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { keyAppVersion } from 'variable';

const RequestUpdateVersion = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);

    const onRefresh = async () => {
        setIsLoading(true);
        const data = await MetaDataService.getAppVersion();
        setIsLoading(false);
        localStorage.setItem(keyAppVersion, data?.data?.getMetaData?.app_version || '1.0');
        const el = document.getElementById('request-update-version');
        el.remove();
        window.location.pathname = '/';
    };

    return (
        <div className="w-screen h-screen fixed top-0 left-0 z-[99] flex items-center justify-center p-4 bg-black/40">
            <Spin spinning={isLoading}>
                <div className="w-[calc(100vw-32px)] h-fit bg-white rounded-lg shadow-sm p-4">
                    <p className="text-base font-semibold text-black text-center">
                        {t('components.general.A new version is available')}
                    </p>
                    <p
                        className="mt-5 w-full h-12 rounded-lg text-white bg-pink-500 text-base font-semibold flex items-center justify-center"
                        onClick={onRefresh}
                    >
                        {t('components.general.Refresh')}
                    </p>
                </div>
            </Spin>
        </div>
    );
};

export default RequestUpdateVersion;
