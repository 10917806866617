function base64ToBlob(base64String) {
    if (!base64String) return;
    const base64WithoutPrefix = base64String?.replace(/^data:[^;]+;base64,/, '');

    const byteCharacters = atob(base64WithoutPrefix);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    let contentType = '';
    switch (byteArray[0]) {
        case 0xff:
            contentType = 'image/jpeg';
            break;
        case 0x89:
            contentType = 'image/png';
            break;
        case 0x47:
            contentType = 'image/gif';
            break;
        case 0x49:
        case 0x4d:
            contentType = 'image/tiff';
            break;
        case 0x42:
            contentType = 'image/bmp';
            break;
        default:
            contentType = 'application/octet-stream';
            break;
    }

    const blob = new Blob([byteArray], { type: contentType });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
}

export default base64ToBlob;
