import HOME_QUERY from 'gql/home';
import { handleGraphQLGet } from 'services';

// HOT TOPIC IN HOME PAGE
const HOME_SERVICE = {
    getHotTopic: () => handleGraphQLGet({
        schema: HOME_QUERY.GET_NEW_HOME_PAGE,
    }),
    getMetaData: () => handleGraphQLGet({
        schema: HOME_QUERY.GET_META
    })
}

export default HOME_SERVICE
