import FEED_QUERY from 'gql/feed';
import { handleGraphQLMutation } from 'services';

const FEED_SERVICE = {
    getAdsById: ({ body }) => handleGraphQLMutation({
        schema: FEED_QUERY.GET_FEED_LIST,
        body,
    }),
}

export default FEED_SERVICE