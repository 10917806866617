import React, { useRef } from 'react';
import { keyToken, timeBuild } from './variable';

function withClearCache(Component) {
    function ClearCacheComponent(props) {
        const componentMounted = useRef(false);
        if (!componentMounted.current) {
            fetch('/meta.json')
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersionDate = meta.buildDate;
                    let currentVersionDate = localStorage.getItem(timeBuild);
                    currentVersionDate = parseInt(currentVersionDate);
                    if (!currentVersionDate || latestVersionDate > currentVersionDate) {
                        const oldToken = localStorage.getItem(keyToken);
                        localStorage.clear();
                        localStorage.setItem(keyToken, oldToken);
                        localStorage.setItem(timeBuild, new Date().getTime());
                        if (currentVersionDate) {
                            refreshCacheAndReload();
                        }
                    }
                });
            componentMounted.current = true;
        }

        const refreshCacheAndReload = () => {
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            }
            window.location.href = window.location.pathname + window.location.search;
        };

        return (
            <React.Fragment>
                <Component {...props} />
            </React.Fragment>
        );
    }

    return ClearCacheComponent;
}

export default withClearCache;
