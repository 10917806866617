import axios from 'axios';

const AdsService = {
    endpoint: process.env.REACT_APP_URL_API,
    getAdsList: async (pageSize, filter, currentPage, sort) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
                query getAdsList($pageSize: Int, $filter: AdsListFilterMapInput,$currentPage: Int, $sort: [AdsSortInput] ) {
                    getAdsList(
                            pageSize:$pageSize
                            sort: $sort
                            filter: $filter
                            currentPage: $currentPage
                        ) {
                        items {
                            advertisement_id
                            type
                            status
                            enable
                            customer_id
                            title
                            salon_address
                            postal_code
                            country
                            city
                            state
                            salary
                            price
                            description
                            created_at
                            lat
                            lng
                            gallery {
                                image
                                image_url
                            }
                            customer {
                                email
                                firstname
                                lastname
                            }
                            contact {
                              ads_apply_id
                              name
                              avatar
                            }
                            favorites_id
                            url_key
                        }
                        page_info  {
                            page_size
                            current_page
                            total_page
                        }
                    }
                }
        `,
            variables: {
                pageSize,
                filter,
                currentPage,
                sort,
            },
        });
        return data;
    },
    createAds: async (input) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
                mutation createAds($input: AdsCreateInput!) {
                    createAds(input: $input) {
                        advertisement_id
                        salon_id
                        city
                        country
                        customer_id
                        description
                        gallery {
                            image
                            image_url
                        }
                        postal_code
                        status
                        created_at
                        lat
                        lng
                        url_key
                    }
                }
                  
            `,
            variables: {
                input,
            },
        });
        return data;
    },
    editAds: async (input) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
                mutation editAds($input: AdsUpdateInput!) {
                    editAds(input: $input) {
                        advertisement_id
                        salon_id
                        url_key
                    }
                }
                  
            `,
            variables: {
                input,
            },
        });
        return data;
    },
    getAdsDetails: async (id, url_key) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
                query adsDetail($id: String!, $url_key: String!) {
                    adsDetail(id: $id, url_key: $url_key) {
                        advertisement_id
                        type
                        status
                        enable
                        customer_id
                        title
                        salon_address
                        city
                        postal_code
                        country
                        salon_id
                        city
                        state
                        salary
                        price
                        description
                        created_at
                        gallery {
                            image
                            image_url
                        }
                        customer {
                            email
                            firstname
                            lastname
                            avatar
                            url_key
                        }
                        favorites_id
                        contact {
                            ads_apply_id
                            customer_id
                            email
                            message
                            is_contact
                            phone
                            avatar
                            created_at
                            name
                            url_key
                        }
                        url_key
                    }
                }
                  
            `,
            variables: {
                id: id || '',
                url_key: url_key || '',
            },
        });
        return data;
    },
    getAdsStatus: async (url_key) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
                query adsDetail($url_key: String!) {
                    adsDetail(url_key: $url_key) {
                        advertisement_id
                        status
                    }
                }
                  
            `,
            variables: {
                url_key,
            },
        });
        return data;
    },
    createContactAds: async (input) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
                mutation createContactAds($input: ApplyAdsCreateInput!) {
                    createContactAds(input: $input) {
                            result
                        }
                    }  
            `,
            variables: {
                input,
            },
        });
        return data;
    },
    deleteAds: async (id) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
              mutation deleteAds($id: Int!) {
                deleteAds(
                    ads_id: $id
                ) {
                    result
                }
              }
            `,
            variables: {
                id,
            },
        });
        return data;
    },
    requestContactedAds: async (applyAdsId) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
            mutation contactedAds($applyAdsId: Int!) {
                contactedAds(applyAdsId: $applyAdsId) {
                    result
                }
              }
                  
            `,
            variables: {
                applyAdsId,
            },
        });
        return data;
    },
    getMyAdsList: async (customerId, type) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
            query getMyAdvertisement($customerId: String!) {
                getMyAdvertisement(
                    pageSize: 3
                    filter: {
                        customer_id: $customerId,
                    }
                    currentPage: 1
                ) {
                    items {
                        advertisement_id
                        type
                        status
                        enable
                        customer_id
                        title
                        salon_address
                        city
                        postal_code
                        country
                        state
                        salary
                        price
                        description
                        created_at
                        gallery {
                            image
                            image_url
                        }
                        customer {
                            email
                            firstname
                            lastname
                        }
                        favorites_id
                    }
                    page_info  {
                        page_size
                        current_page
                        total_page
                    }
                }
            }
            `,
            variables: {
                customerId,
            },
        });
        return data;
    },
    getFavoritesList: async (type) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: ` 
        query getFavoritesList($type: String) {
            getFavoritesList(
                filter: {
                  type: $type
                }
            ){
                items {
                    advertisement_id
                    type
                    customer_id
                    title
                    salon_address
                    city
                    country
                    state
                    salary
                    price
                    created_at
                    favorites_id
                    is_contact
                    gallery {
                        image
                        image_url
                    }
                    customer {
                        email
                        firstname
                        lastname
                    }
                    url_key
                }
            }
        }`,
            variables: {
                type,
            },
        });
        return data;
    },
    addAdsFavorites: async (adsId) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
        mutation addAdsFavorites($adsId: Int!) {
          addAdsFavorites (
              advertisement_id: $adsId
          ) {
              result
              favorites_id
          }
        }
      `,
            variables: {
                adsId,
            },
        });
        return data;
    },
    deleteAdsFavorites: async (favorites_id) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
        mutation deleteFavorites($adsId: Int!) {
          deleteFavorites (
            favorites_id: $adsId
          ) {
              result
          }
        }
      `,
            variables: {
                adsId: favorites_id,
            },
        });
        return data;
    },
    getCountry: async () => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: ` 
        query {
          country(id: "US") {
              id
              two_letter_abbreviation
              three_letter_abbreviation
              full_name_locale
              full_name_english
              available_regions {
                  id
                  code
                  name
                  cities {
                      id
                      name
                      lat
                      lng
                  }
              }
          }
      }
    `,
        });
        return data;
    },
    getAdsContactedList: async (pageSize, filter, currentPage) => {
        const { data } = await axios.post(AdsService.endpoint, {
            query: `
      query getAdsContactedList($pageSize: Int, $filter: AdsListFilterInput,$currentPage: Int) {
          getAdsContactedList(
            pageSize:$pageSize
            filter: $filter
            currentPage: $currentPage
          ){
              page_info  {
                  page_size
                  current_page
                  total_page
              }
              items {
                  advertisement_id
                  type
                  customer_id
                  title
                  city
                  salary
                  state
                  price
                  created_at
                  gallery {
                      image
                      image_url
                  }
                  customer {
                      email
                      firstname
                      lastname
                      avatar
                  }
                  favorites_id
                  url_key
              }
          }
      }
        `,
            variables: {
                pageSize,
                filter,
                currentPage,
            },
        });
        return data;
    },
};

export default AdsService;
