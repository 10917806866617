export const checkUserRole = (user, status, roleUpgradeCheck) => {
    // Destructure user properties for easier access
    const {
        authentication_status: authStatus,
        account_type: accountType,
        is_salon_owner: isSalonOwner,
        salon_owner_status: salonOwnerStatus,
    } = user || {};

    // Define authentication validity
    const isAuthenticationValid = authStatus === 'authenticated' || authStatus === 'authenticated_with_certificate';

    // Define statusUserRole object
    const statusUserRole = {
        not_authen: authStatus === 'unauthenticated' || authStatus === 'rejected',
        under_review: authStatus === 'under_review',
        upgraded: {
            manicurist: accountType && accountType === '2' && isAuthenticationValid,
            nails_master: accountType && accountType === '3' && isAuthenticationValid,
            salon_owner: isSalonOwner && salonOwnerStatus,
            salon_owner_under_review: isSalonOwner && !salonOwnerStatus,
        },
    };

    // Return the appropriate role check result
    if (status && statusUserRole[status]) {
        return roleUpgradeCheck ? statusUserRole[status][roleUpgradeCheck] : statusUserRole[status];
    }
};

const getUserRole = (user) => {
    const roleUser = {
        main: 'guest',
    };
    if (+user?.account_type === 1) {
        roleUser.main = 'member';
    }
    if (+user?.account_type === 2) {
        roleUser.main = 'manicurist';
    }
    if (+user?.account_type === 3) {
        roleUser.main = 'nailsMaster';
    }
    if (user?.is_salon_owner && user?.salon_owner_status) {
        roleUser.sub = 'salonOwner';
    }
    if (
        user &&
        (user?.authentication_status === 'unauthenticated' ||
            user?.authentication_status === 'under_review' ||
            user?.authentication_status === 'rejected')
    ) {
        roleUser.main = 'unauthenticated';
    }
    return roleUser;
};

export default getUserRole;
