import ProfileService from 'services/profile';

const checkCustomerHaveRequestVerify = async (user) => {
    const type = 'authentication_documents_submission';
    const values = {
        firstname: user?.firstname,
        lastname: user?.lastname,
        description: 'checkCustomerHaveRequestVerify',
    };
    const data = await ProfileService.requestSupportCustomer(type, values);
    return data?.errors?.[0]?.message === 'You had a request before.';
};

export default checkCustomerHaveRequestVerify;
