import { useMutation, useQuery, useQueryClient } from "react-query"
import ADS_SERVICE from "services/refactor/ads"
import { QUERY_KEYS } from "../queryKeys";

// ============================================================
// ADS QUERIES
// ============================================================
export const useDeleteFavorites = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return ADS_SERVICE.deleteFavorites({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useAddFavorites = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return ADS_SERVICE.addFavorites({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useGetAdsById = (adsId = '') => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_ADS_BY_ID, adsId],
        queryFn: () => {
            return ADS_SERVICE.getAdsById({
                body: {
                    id: adsId
                }
            })
        },
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
    })
}

export const useCreateContactAds = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return ADS_SERVICE.createContactAds({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}