import {
    gql
} from '@apollo/client'

const GET_FEED_LIST_QUERY = gql`
query getFeedList($customerId: String, $pageSize: Int, $currentPage: Int){
    getFeedList (
        pageSize: $pageSize,
        filter: {
            customer_id: $customerId
            status: {
                eq: "approved" 
            },
        }
        sort: [
            { created_at: DESC }
        ],
        currentPage: $currentPage
    ) { 
        items {
            feed_id
            status
            type
            content
            is_pinned
            customer_id
            created_at
            privacy
            media_gallery {
                id
                media_type
                video_url
                path
                video_thumbnail
            }
            short_time
            customer {
                email
                firstname
                lastname
                avatar
            }
            total_like
            # link
            # link_title
            # link_description
            # link_thumbnail
            products {
                id
                sku
                name
                price
                qty
                description
                image {
                    name
                    url
                }
            }
            background
            font
        }
        page_info {
            page_size
            current_page
            total_page
        }
    }
}
`
const extractQueryInfo = (query) => ({
    query: query.loc.source.body,
    name: query.definitions[0].name.value,
});

const FEED_QUERY = {
    GET_FEED_LIST: extractQueryInfo(GET_FEED_LIST_QUERY)
};

export default FEED_QUERY;