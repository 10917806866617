import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { useAuth } from 'globalContext';
import useApplicationContext from 'hooks/useApplicationContext';
import { useGetCustomerProfileById } from 'lib/react-query/queriesAndMutation';
import { useGetMetaData } from 'lib/react-query/queriesAndMutation/home';
import _ from 'lodash';
import React, { createContext, useContext, useEffect } from 'react';
import { getIv, parseJSON } from 'utils';
import parseRoleUser from 'utils/parseRoleUser';

export const defaultApplicationContext = {
    device: {
        isMobile: false,
        isDesktop: true
    },
    platform: {
        tingTing: false
    },
    isLoggedIn: false,
    topVisibility: {
        desktop: true,
        mobile: false
    },
    user: null,
    isFullScreen: false,
    db: {},
    updaters: {
        updatePlatForm: () => { },
        updateIsFullScreen: () => { },
        updateUser: () => { },
        updateIsLoggedIn: () => { },
        updateTopVisibility: () => { },
        updateDB: () => { } // Fire base
    }
}

export const ApplicationContext = createContext(defaultApplicationContext)

const ApplicationProvider = ({ children }) => {
    const { applicationContext, dispatchApplicationContext } = useApplicationContext()

    const { isLoggedIn } = applicationContext

    const isNofication = parseJSON(localStorage.getItem('dont-show-again'));
    const { mutateAsync: getSelf } = useGetCustomerProfileById()
    const { data: metaData } = useGetMetaData()

    useEffect(() => {
        applicationContext.updaters.updatePlatForm(isNofication)
    }, [isNofication])

    const handleGetCustomer = async () => {
        const { ok, data } = await getSelf({})
        if (ok) {
            const role = parseRoleUser(data)
            applicationContext.updaters.updateUser({ ...data, role })
        } else {
            applicationContext.updaters.updateUser(null)
        }
    }

    // getUser for token
    useEffect(() => {
        handleGetCustomer()
    }, [isLoggedIn])

    return (
        <ApplicationContext.Provider value={applicationContext}>
            {children}
        </ApplicationContext.Provider>
    )
}

export default ApplicationProvider