import imageValidate from 'utils/imageValidate';
import eventImageDefaultBig from 'assets/images/bg_nz_default.png';

import axios from 'axios';

const EventService = {
    endpoint: process.env.REACT_APP_URL_API,
    getEvents: async (pageSize, currentPage, status, sort_direction, customer_id) => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
                query getEventList($pageSize: Int!, $currentPage: Int!, $status: String!, $sort_direction: String!, $customer_id: String) {
                    getEventList(pageSize: $pageSize, currentPage: $currentPage, filter: {status: $status, customer_id: $customer_id}, sort: {field: "time_start",direction: $sort_direction}) {
                        items {
                            event_id
                            title
                            status
                            image
                            image_url
                            remindMe_id
                            link_live_stream
                            time_start
                            time_end
                            duration
                            customer_id
                            description
                            created_at
                            url_key
                            event_trigger {
                                event_trigger_id
                                sku
                                event_id
                                trigger_time
                                price
                                image
                                name
                            }
                            customer {
                                email
                                firstname
                                lastname
                                avatar
                                url_key
                            }
                            moderator {
                              moderator_id
                          }
                        }
                    }
                }
            `,
            variables: {
                pageSize: pageSize || 10,
                currentPage: currentPage || 1,
                status: status || 'Published',
                sort_direction: sort_direction || 'DESC',
                customer_id,
            },
        });
        const events = data?.data?.getEventList?.items;
        for (let i = 0; i < events?.length; i++) {
            const result = await imageValidate.checkUrlNotFound(events[i]?.image_url);
            events[i].image_url = result ? events[i]?.image_url : eventImageDefaultBig;
        }
        if (data?.data?.getEventList) {
            data.data.getEventList.items = events;
        }
        return data?.data;
    },
    getEventDetail: async (id, url_key, type, password) => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
                query eventDetail ($id: String!, $url_key: String!, $type: String!, $password: String) {
                    eventDetail (id: $id, url_key: $url_key, type: $type, password: $password) {
                        event_id
                        title
                        status
                        image
                        image_url
                        link_live_stream
                        time_start
                        time_end
                        duration
                        remindMe_id
                        customer_id
                        description
                        created_at
                        url_key
                        event_trigger {
                          event_trigger_id
                          sku
                          event_id
                          trigger_time
                          name
                          price
                          image
                        }
                        customer {
                            email
                            firstname
                            lastname
                            avatar
                            url_key
                        }
                        moderator {
                          moderator_id
                      }
                    }
                }
            `,
            variables: {
                id: id || '',
                url_key: url_key || '',
                type: type || 'live',
                password,
            },
        });
        const result = await imageValidate.checkUrlNotFound(data?.data?.eventDetail?.image_url);
        if (!result) {
            if (data.data.eventDetail) data.data.eventDetail.image_url = eventImageDefaultBig;
        }
        return data?.data;
    },
    getEventDetailToPreview: async (url_key, type, password) => {
        const data = await axios.post(EventService.endpoint, {
            query: `
                query eventDetail ($url_key: String!, $type: String!, $password: String) {
                    eventDetail (url_key: $url_key, type: $type, password: $password) {
                        event_id
                        title
                        status
                        image
                        image_url
                        link_live_stream
                        time_start
                        time_end
                        duration
                        remindMe_id
                        customer_id
                        description
                        created_at
                        event_trigger {
                          event_trigger_id
                          sku
                          event_id
                          trigger_time
                          name
                          price
                          image
                        }
                        customer {
                            email
                            firstname
                            lastname
                            avatar
                        }
                        moderator {
                          moderator_id
                      }
                    }
                }
            `,
            variables: {
                url_key,
                type: type || 'live',
                password,
            },
        });
        const result = await imageValidate.checkUrlNotFound(data?.data?.eventDetail?.image_url);
        if (!result) {
            if (data.data.eventDetail) data.data.eventDetail.image_url = eventImageDefaultBig;
        }
        return data?.data;
    },
    sendRequestCreateEvent: async (input) => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
        mutation requestCreateEvent ($input: RequestCreateEventInput!) {
          requestCreateEvent (input: $input) {
            result
          }
        }
      `,
            variables: {
                input,
            },
        });
        return data;
    },
    downloadTemplate: async () => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
        query getProductListTemplate {
          getProductListTemplate {
            file
            link
          }
        }
      `,
        });
        return data;
    },
    approveEvent: async (eventId) => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
        mutation approveEvent ($eventId: String!) {
          approveEvent (eventId: $eventId) {
            result
          }
        }
      `,
            variables: {
                eventId,
            },
        });
        return data;
    },
    getCustomerCart: async () => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
        query customerCart {
          customerCart {
            id
            items {
              id
              product {
                name
                sku
                stock_status
                id
                __typename
                price_range {
                  maximum_price {
                    regular_price {
                      value
                      currency
                    }
                    final_price {
                      value
                      currency
                    }
                  }
                  minimum_price {
                    regular_price {
                      value
                      currency
                    }
                    final_price {
                      value
                      currency
                    }
                  }
                }
                image {
                  url
                }
              }
              ... on ConfigurableCartItem {
                configurable_options {
                  option_label
                  value_label
                }
                configured_variant {
                  id
                  name
                  sku
                  stock_status
                  price_range{
                    minimum_price{
                      regular_price{
                        value
                        currency
                      }
                      final_price {
                          value
                          currency
                      }
                    }
                    maximum_price{
                      regular_price{
                        value
                        currency
                      }
                      final_price {
                          value
                          currency
                      }
                    }
                  }
              }
              }
              quantity
            }
          }
        }
      `,
        });
        if (data?.data?.customerCart) {
            data.data.customerCart.items = data?.data?.customerCart?.items?.filter((item) => !!item);
        }
        return data;
    },
    addToCart: async (input, id) => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
        mutation addSimpleProductsToCart ($input: AddSimpleProductsToCartInput!) {
          addSimpleProductsToCart (input: $input) {
            cart {
              items {
                id
                product {
                  name
                  sku
                  image {
                    url
                  }
                  price_range {
                    maximum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                    minimum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                  }
                }
                quantity
              }
            }
          }
        }
      `,
            variables: {
                input,
            },
        });
        return data;
    },
    updateCart: async (cart_id, cart) => {
        const cart_items = cart?.map((item) => {
            return {
                cart_item_id: item?.id,
                quantity: +item?.quantity,
            };
        });
        const { data } = await axios.post(EventService.endpoint, {
            query: `
        mutation updateCartItems ($input: UpdateCartItemsInput!) {
          updateCartItems (input: $input) {
            cart {
              items {
                product {
                  name
                  sku
                }
                quantity
              }
              prices {
                grand_total {
                  value
                  currency
                }
              }
            }
          }
        }
      `,
            variables: {
                input: {
                    cart_id,
                    cart_items,
                },
            },
        });
        return data;
    },
    remind: async (event_id) => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
              mutation addRemindMe ($event_id: String!) {
                addRemindMe (event_id: $event_id) {
                  result
                }
              }
            `,
            variables: {
                event_id,
            },
        });
        return data;
    },
    unRemind: async (remind_id) => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
              mutation unRemindMe ($remind_id: String!) {
                unRemindMe (remind_id: $remind_id) {
                  result
                }
              }
            `,
            variables: {
                remind_id,
            },
        });
        return data;
    },
    getProduct: async (sku) => {
        const data = await axios.post(EventService.endpoint, {
            query: `
        query products {
          products(filter: {sku: {in: [${sku}] } }) {
            items {
              id
              attribute_set_id
              name
              sku
              stock_status
              image {
                url
              }
              description {
                html
              }
              __typename
              price_range{
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
              }
                maximum_price {
                  regular_price {
                  value
                  currency
                  }
                  final_price {
                  value
                  currency
                  }
              }
              }
              categories {
                id
              }
              ... on ConfigurableProduct {
                configurable_options {
                  id
                  attribute_id_v2
                  label
                  position
                  use_default
                  attribute_code
                  values {
                    value_index
                    label
                  }
                  product_id
                }
                variants {
                  product {
                    id
                    name
                    sku
                    stock_status
                    image {
                      url
                    }
                    attribute_set_id
                    ... on PhysicalProductInterface {
                      weight
                    }
                    price_range{
                      minimum_price{
                        regular_price{
                          value
                          currency
                        }
                        final_price {
                          value
                          currency
                        }
                      }
                      maximum_price {
                        regular_price {
                            value
                        }
                        final_price {
                          value
                          currency
                        }
                    }
                    }
                  }
                  attributes {
                    uid
                    label
                    code
                    value_index
                  }
                }
              }
            }
          }
        }
      `,
        });
        return data?.data;
    },
    addConfigurableProductsToCart: async (input, cart_id) => {
        return axios.post(EventService.endpoint, {
            query: `
        mutation addConfigurableProductsToCart($input: AddConfigurableProductsToCartInput){
          addConfigurableProductsToCart(input: $input){
            cart {
              items {
                uid
                quantity
                product {
                  id
                  name
                  sku
                  image {
                    url
                  }
                  price_range {
                    maximum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                    minimum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                  }
                }
                ... on ConfigurableCartItem {
                  configurable_options {
                    option_label
                  }
                }
              }
            }
          }
        }
      `,
            variables: {
                input,
            },
        });
    },
    resendPasswordEvent: async (url_key) => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
            mutation resendPasswordEvent ($url_key: String!) {
              resendPasswordEvent (url_key: $url_key) {
                result
              }
            }
          `,
            variables: {
                url_key,
            },
        });
        return data;
    },
    addProductToCart: async (input) => {
        return axios.post(EventService.endpoint, {
            query: `
        mutation addProductsToCart($cartId: String!, $cartType: String!, $cartItems: [CartItemInput!]!) {
          addProductsToCart(cartId: $cartId, cartType: $cartType, cartItems: $cartItems) {
            cart {
              items {
                product {
                  name
                  sku
                }
                quantity
              }
            }
            user_errors {
              code
              message
            }
          }
        }
      `,
            variables: {
                cartId: input?.cart_id,
                cartType: input?.cart_type,
                cartItems: input?.cart_items,
            },
        });
    },
    getAllProductBySellerId: async (id) => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
        query productsBySellerId($seller_id: Int!) {
          productsBySellerId(seller_id: $seller_id) {
            items {
              id
              attribute_set_id
              name
              sku
              description {
                html
              }
              image {
                url
              }
              __typename
              price_range{
                minimum_price{
                  regular_price{
                    value
                    currency
                  }
                }
              }
              categories {
                id
              }
              ... on GroupedProduct {
                items {
                  qty
                  position
                  product {
                    stock_status
                    sku
                    name
                    __typename
                    url_key
                  }
                }
              }
              ... on ConfigurableProduct {
                configurable_options {
                  id
                  attribute_id_v2
                  label
                  position
                  use_default
                  attribute_code
                  values {
                    value_index
                    label
                  }
                  product_id
                }
                variants {
                  product {
                    id
                    name
                    sku
                    attribute_set_id
                    ... on PhysicalProductInterface {
                      weight
                    }
                    price_range {
                      minimum_price {
                        regular_price {
                          value
                          currency
                        }
                      }
                    }
                  }
                  attributes {
                    uid
                    label
                    code
                    value_index
                  }
                }
              }
            }
          }
        }
      `,
            variables: {
                seller_id: Number(id),
            },
        });
        return data;
    },
    getStockStatusProduct: async (sku) => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
        query products {
          products(filter: {sku: {in: [${sku}] } }) {
            items {
              stock_status
              sku
            }
          }
        }
      `,
        });
        return data;
    },
    createEmptyCart: async () => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
        mutation createEmptyCart {
          createEmptyCart
        }
      `,
        });
        return data?.data?.createEmptyCart;
    },
    checkGuestCartActive: async (cart_id) => {
        const { data } = await axios.post(EventService.endpoint, {
            query: `
        query cart($cart_id: String!) {
          cart (cart_id: $cart_id) {
            items {
              id
            }
          }
        }
      `,
            variables: {
                cart_id,
            },
        });
        return data?.data?.cart;
    },
};

export default EventService;
