import { useReducer } from 'react'
import { defaultApplicationContext } from 'components/ApplicationContext'

const applicationReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_DEVICE':
            return {
                ...state,
                device: action.payload
            }
        case "UPDATE_PLATFORM": {
            return {
                ...state,
                platform: action.payload
            }
        }
        case "UPDATE_IS_FULL_SCREEN": {
            return {
                ...state,
                isFullScreen: action.payload
            }
        }
        case "UPDATE_USER": {
            return {
                ...state,
                user: action.payload
            }
        }
        case "UPDATE_IS_LOGGED_IN": {
            return {
                ...state,
                isLoggedIn: action.payload
            }
        }
        case "UPDATE_TOP_VISIBILITY": {
            return {
                ...state,
                topVisibility: action.payload
            }
        }
        case "UPDATE_DATABASE": {
            return {
                ...state,
                db: action.payload
            }
        }
        default:
            return state
    }
}

const useApplicationContextReducer = () => {
    const [applicationContext, dispatchApplicationContext] = useReducer(applicationReducer, defaultApplicationContext)

    return {
        applicationContext,
        dispatchApplicationContext
    }
}

export default useApplicationContextReducer