const parseRoleUser = (user) => {
    switch (user?.account_type) {
        case '1':
            return 'MEMBER'
        case '2':
            return 'MAINCURIST'
        case '3':
            return 'NAILS_MASTER'
        default:
            return 'GUEST'
    }
}

export const parseTypeUser = (user) => {
    switch (user?.account_type) {
        case '1':
            return 'Member'
        case '2':
            return 'Maincurist'
        case '3':
            return 'Nails Master'
        default:
            return ''
    }
}

export default parseRoleUser