import ADS_QUERY from 'gql/ads';
import { handleGraphQLMutation } from 'services';

const ADS_SERVICE = {
    addFavorites: ({ body }) => handleGraphQLMutation({
        schema: ADS_QUERY.ADD_FAVORITES,
        body,
    }),
    deleteFavorites: ({ body }) => handleGraphQLMutation({
        schema: ADS_QUERY.DELETE_FAVORITIES,
        body,
    }),
    getAdsById: ({ body }) => handleGraphQLMutation({
        schema: ADS_QUERY.GET_ADS_BY_ID,
        body,
    }),
    createContactAds: ({ body }) => handleGraphQLMutation({
        schema: ADS_QUERY.CREATE_CONTACT,
        body,
    })
}

export default ADS_SERVICE