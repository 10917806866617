import axios from 'axios';

const MetaDataService = {
    endpoint: process.env.REACT_APP_URL_API,
    getMetaData: async () => {
        const { data } = await axios.post(MetaDataService.endpoint, {
            query: `
                query {
                    getMetaData {
                        logo
                        logo_mobile
                        host
                        system {
                            username
                            logo
                        }
                        app_version
                        reaction_enabled
                        reaction_comment_enabled
                        ecommerce_enabled
                        master_class_banner
                        master_class_banner_mobile
                        avatar_default
                        cover_default
                        permission
                        knz
                        pnz
                        firebase {
                            api_key
                            api_id
                            auth_domain
                            storage_bucket
                            messaging_sender_id
                            url_database
                        }
                        recaptcha {
                            api_website_key
                        }
                        phone_countries {
                            country_name
                            region_code
                            country_calling_code
                            enable
                        }
                        is_enable_resume
                        is_enable_otp_login
                        local_timezone
                        local_code
                    }
                }
            `,
        });
        return data;
    },
    getBannerHomePage: async (screen = 'desktop') => {
        const { data } = await axios.post(MetaDataService.endpoint, {
            query: `
                query getBanners($screen: String!) {
                    getBanners(screen: $screen) {
                        id
                        banner_url
                    }
                }
            `,
            variables: {
                screen,
            },
        });
        return data;
    },
    getAppVersion: async () => {
        const { data } = await axios.post(MetaDataService.endpoint, {
            query: `
                query {
                    getMetaData {
                        app_version
                    }
                }
            `,
        });
        return data;
    },
};

export default MetaDataService;
