const updateListReactQuery = ({ queryClient, queryKey, condition, updateFields = {} }) => {
    queryClient.setQueryData(queryKey, (old) => {
        old.pages = old?.pages?.map((page) => {
            return page?.map((item) => {
                if (condition(item)) {
                    return { ...item, ...updateFields };
                }
                return item;
            });
        });
        return old;
    });
};

export default updateListReactQuery;
