// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets//images/background.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-spin-nz {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: 'no-repeat';
  background-size: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/SpinNZ/index.less"],"names":[],"mappings":"AAAA;EACI,yDAAA;EACA,8BAAA;EACA,sBAAA;AACJ","sourcesContent":[".bg-spin-nz {\n    background-image: url(../../assets//images/background.webp);\n    background-repeat: 'no-repeat';\n    background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
