import {
    gql
} from '@apollo/client'
import { extractQueryInfo } from 'gql'

const GET_CUSTOMER_PROFILE_BY_ID_QUERY = gql`
    query customerProfile($customerId: String, $url_key: String){
        customerProfile (customerId: $customerId, url_key: $url_key) {
            __typename
            id
            avatar
            cover_image
            image_bot
            firstname
            lastname
            authentication_status
            account_type
            description
            phone_number
            secondary_phone_number
            email
            secondary_mail
            location
            account_status
            is_salon_owner
            country_code
            phone_country_code
            setup_password_yet
            addresses {
                id
                street
                city
                region {
                    region
                    region_code
                    region_id
                }
                postcode
                country_id
                country_code
            }
            is_verify_email
            salon_owner_status
            receive_sms
            receive_email
            social {
                social_customer_id
                social_id
                username
                type
                email
            }
            seller_id
            meta_title
            meta_keywords
            meta_description
            result_vote {
                id
                vote_id
                vote_name
                url_key
                position
                total_vote
            }
        }
    }
`
const UPLOAD_CUSTOMER_V2_QUERY = gql`
    mutation updateCustomerV2($input: CustomerInputUpdate!) {
        updateCustomerV2(input: $input) {
            result
        }
    }
`
const GET_CUSTOMER_MEDIA_QUERY = gql`
    query getCustomerMedia($type: String, $customerId: String, $pageSize: Int, $currentPage: Int){
        getCustomerMedia(
            filter: {
                type: $type
                customer_id:  $customerId
            }
            currentPage: $currentPage
            pageSize: $pageSize
            ){
                items {
                    media_type
                    path
                    video_url
                    video_thumbnail
                    feed_id
                    content
                    short_time
                    customer_id
                    customer {
                        email
                        firstname
                        lastname
                        avatar
                    }
                }
                page_info {
                    current_page,
                    page_size,
                    total_page
                }
        }
    }
`
const CUSTOMER_QUERYS = {
    GET_CUSTOMER_PROFILE_BY_ID: {
        query: GET_CUSTOMER_PROFILE_BY_ID_QUERY.loc.source.body,
        name: GET_CUSTOMER_PROFILE_BY_ID_QUERY.definitions[0].name.value,
    },
    UPLOAD_CUSTOMER_V2: extractQueryInfo(UPLOAD_CUSTOMER_V2_QUERY),
    GET_CUSTOMER_MEDIA: extractQueryInfo(GET_CUSTOMER_MEDIA_QUERY)
}

export default CUSTOMER_QUERYS