import moment from 'moment';

const convertUTCToLocalTimezone = (utcTime) => {
    return moment(utcTime + 'Z').toLocaleString();
};

const getTimeRange = (time, isUtc = true) => {
    if (isUtc) {
        const localTime = convertUTCToLocalTimezone(time);
        return moment(localTime).fromNow();
    }
    return moment(time).fromNow();
};

const formatDate = (date) => date?.slice(0, 10)?.replaceAll('-', '/');

export { convertUTCToLocalTimezone, getTimeRange, formatDate };
