const RouterLinks = (name, type) => {
    const array = {
        Home: '/',
        Event: '/event',
        'Event Detail': '/event/detail',
        'Event Live': '/event/live',
        'End Event': '/event/end-event',
        'Create Event': '/create-event',
        'Review Event': '/review-event',
        'My Basket': '/event/my-basket',
        Profile: '/profile',
        'Original Post': '/post-detail',
        SettingNotification: '/setting-notification',
        Manicurist: '/manicurist',
        SellSalon: '/sell-salon',
        AdsDetails: '/ads-details',
        JobDetails: '/job-details',
        ForSaleDetails: '/for-sale-details',
        SalonDetails: '/salon-details',
        AdJobs: '/jobs',
        AdForSale: '/for-sale',
        SavedAds: '/saved-ads',
        CreateSalon: '/create-salon',
        CreatePost: '/create-post',
        EditPost: '/edit-post',
        'Account Info': '/account-info',
        Terms: '/terms-conditions',
        Feedback: '/feedback',
        Legal: '/legal',
        TermAndConditions: '/legal/terms-and-conditions',
        PrivacyPolicy: '/legal/privacy-policy',
        DataSecurityPolicy: '/legal/data-security-policy',
        CookiePolicy: '/legal/cookie-policy',
        CommunityGuidelines: '/legal/community-guidelines',
        AccessibilityStatement: '/legal/accessibility-statement',
        DisputeResolutionAgreement: '/legal/dispute-resolution-agreement',
        CommunityParticipationAgreement: '/legal/community-participation-agreement',
        SellersCommonAgreement: '/legal/sellers-common-agreement',
        BuyerAgreement: '/legal/buyer-agreement',
        ReturnAndRefundPolicy: '/legal/return-and-refund-policy',
        ShippingPolicy: '/legal/shipping-policy',
        IntellectualProperty: '/legal/intellectual-property',
        NotFound: '/not-SellersCommonAgreement',
        Forbidden: '/forbidden',
        AccessPermissionDenied: '/access-permission-denied',
        Account: '/account',
        'Forgot Password': '/account/forgot-password',
        'Account Verify': '/account/verify',
        SignUp: '/account/sign-up',
        'Create Profile': '/account/create-profile',
        Success: '/account/success',
        SignIn: '/account/sign-in',
        'Account Setting': '/account/settings',
        ChangeName: '/account/change-name',
        'Change Primary Phone': '/account/change-primary-phone',
        'Change Primary Email': '/account/change-primary-email',
        'Change Password': '/account/change-password',
        'Change Second Phone': '/account/change-second-phone',
        'Change Second Email': '/account/change-second-email',
        'Change Authentication Document': '/account/change-authentication-document',
        'Delete Account': '/account/delete',
        'Upgrade Account': '/account/upgrade',
        'Request Details': '/request-details',
        About: '/about',
        Maintain: '/maintain',
        SocialLink: '/account/social-link',
        SocialSuccess: '/account/social-success',
        Search: '/search',
        'Add to the device': '/add-to-the-device',
        'Report Page': '/report',
        'Setting video toturial': '/setting-video',
        'Guest Create Account After Buy Product': '/account/verify-password',
        'Create Resume': '/create-resume',
        'Product Detail': '/product',
        Vote: '/vote',
        'customer_servivce': "/customer-service",
        "request_support": "/request_support",
        "about_nz": '/about',
        "add_device": "/add-to-the-device"
    };

    const apis = {};

    if (type === 'api') return apis[name];
    return array[name];
};
export default RouterLinks;
