import parseJSON from './parseJSON';

const getRegion = (stateName) => {
    const statesArr = parseJSON(localStorage.getItem('states')) || [];
    const state = {};
    statesArr?.forEach((item) => {
        if (item?.name === stateName) {
            state.region = item?.name;
            state.region_code = item?.code;
            state.region_id = item?.id;
        }
    });
    return state;
};

const getAddress = (country_code, address, zip, city) => {
    return {
        country_code: country_code || 'US',
        street: address || '',
        postcode: zip || '',
        city,
    };
};

const getSalonInfomation = (profile, salonAddress) => {
    const gallery = profile?.gallery?.map((item) => ({
        name: item?.name.replaceAll(' ', ''),
        base64_encoded_file: item?.base64_encoded_file || item?.thumbUrl,
    }));

    const business_license = profile?.business_license?.map((item) => ({
        name: item?.name.replaceAll(' ', ''),
        base64_encoded_file: item?.base64_encoded_file || item?.thumbUrl,
    }));

    return [
        {
            name: profile?.name,
            description: profile?.about || '',
            gallery: gallery || [],
            business_license,
            address: salonAddress?.postcode
                ? {
                      ...salonAddress,
                      ...(profile?.lat && { lat: profile?.lat?.toString() }),
                      ...(profile?.lng && { lng: profile?.lng?.toString() }),
                      region: {
                          region: profile?._state,
                      },
                  }
                : undefined,
        },
    ];
};

const getPersonalAddress = (user, profile, persoalAddress) => {
    return {
        ...persoalAddress,
        firstname: user?.firstname || profile?.firstname || '',
        lastname: user?.lastname || profile?.lastname || '',
        telephone: user?.phone_number?.replaceAll('+', '') || profile?.telephone?.replaceAll('+', '') || '',
    };
};

export { getRegion, getAddress, getSalonInfomation, getPersonalAddress };
