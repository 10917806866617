import React from 'react';
import ReactModal from './commons/Modal/ReactModal';
import NailsZoneSpin from 'components/SpinNZ';

const NewLoading = ({ isLoading, children }) => {
    return (
        <>
            {children}
            {isLoading && (
                <ReactModal borderRadius="0px">
                    <div className="w-screen h-screen">
                        <NailsZoneSpin isLoading={true}>
                            <div className="w-screen h-[100dvh]"></div>
                        </NailsZoneSpin>
                    </div>
                </ReactModal>
            )}
        </>
    );
};

export default NewLoading;
