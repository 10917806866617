import {
    gql
} from '@apollo/client'
import { extractQueryInfo } from 'gql'

const GET_NEW_HOME_PAGE_QUERY = gql`
    query getNewHomepage { 
    getNewHomepage {
        event_list {
            items {
                event_id
                url_key
                title
                status
                image
                image_url
                link_live_stream
                time_start
                time_end
                duration
                customer_id
                description
                created_at
                event_trigger {
                    event_trigger_id
                    sku
                    event_id
                    trigger_time
                    name
                    description
                }
                customer {
                    email
                    firstname
                    lastname
                    avatar
                    url_key
                }
                remindMe_id
                moderator {
                    moderator_id
                }
                add_calendar
                admin_id
            }
        }
        jobs_list {
            items {
                advertisement_id
                type
                status
                url_key
                enable
                customer_id
                title
                salon_address
                city
                postal_code
                country
                state
                lat
                lng
                salary
                price
                description
                created_at
                gallery {
                    image
                    image_url
                    image_bot
                }
                customer {
                    email
                    firstname
                    lastname
                }
                favorites_id
                salon_id
            }
        }
        forsales_list {
            items {
                advertisement_id
                type
                status
                url_key
                enable
                customer_id
                title
                salon_address
                city
                postal_code
                country
                state
                lat
                lng
                salary
                price
                description
                created_at
                gallery {
                    image
                    image_url
                    image_bot
                }
                customer {
                    email
                    firstname
                    lastname
                }
                favorites_id
                salon_id
            }
        }
        votes_list {
            items {
                id
                name
                status
                url_key
                description
                application_start_time
                application_end_time
                voting_start_time
                voting_end_time
                banner
                total_apply
                created_at
                updated_at
                nailsmaster_apply {
                    id
                    firstname
                    lastname
                    avatar
                    url_key
                    status
                    introduction
                    comment
                    images_apply {
                        name
                        url
                    }
                    position
                    total_vote
                }
                is_apply_vote
                user_voted_list
            }
        }
    }
}
`

const GET_META_QUERY = gql`
    query getMetaData{
        getMetaData {
            logo
            logo_mobile
            host
            system {
                username
                logo
            }
            app_version
            reaction_enabled
            reaction_comment_enabled
            ecommerce_enabled
            master_class_banner
            master_class_banner_mobile
            avatar_default
            cover_default
            permission
            knz
            pnz
            firebase {
                api_key
                api_id
                auth_domain
                storage_bucket
                messaging_sender_id
                url_database
            }
            recaptcha {
                api_website_key
            }
            phone_countries {
                country_name
                region_code
                country_calling_code
                enable
            }
            is_enable_resume
            is_enable_otp_login
            local_timezone
            local_code
        }
    }
`
const HOME_QUERY = {
    GET_NEW_HOME_PAGE: extractQueryInfo(GET_NEW_HOME_PAGE_QUERY),
    GET_META: extractQueryInfo(GET_META_QUERY)
}


export default HOME_QUERY
