// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-toast-root {
  margin-top: 20px;
  min-width: 394px;
  max-height: 53px;
}
.custom-toast-container {
  border-radius: 10px !important;
  background-color: #D9D9D9 !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.less"],"names":[],"mappings":"AAIA;EACI,gBAAA;EACA,gBAAA;EACA,gBAAA;AAHJ;AAMA;EACI,8BAAA;EACA,oCAAA;AAJJ","sourcesContent":["// @tailwind base;\n// @tailwind components;\n// @tailwind utilities;\n\n.custom-toast-root {\n    margin-top: 20px;\n    min-width: 394px;\n    max-height: 53px;\n}\n\n.custom-toast-container{\n    border-radius: 10px !important;\n    background-color: #D9D9D9 !important;\n    // height: 53px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
