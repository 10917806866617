import { useEffect, useState } from 'react';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(document.body.clientWidth < 1025);

    const handleWindowResize = () => {
        document.body.clientWidth < 1025 ? setIsMobile(true) : setIsMobile(false);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return {
        isMobile,
    };
};

export default useIsMobile;
