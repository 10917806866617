const encodeImageToBase64 = (url) => {
    return new Promise((resolve, reject) => {
        const xhr = new window.XMLHttpRequest();

        xhr.onload = function () {
            const reader = new FileReader();

            reader.onloadend = function () {
                resolve(reader.result);
            };

            reader.onerror = function (error) {
                reject(error);
            };

            reader.readAsDataURL(xhr.response);
        };

        xhr.onerror = function (error) {
            reject(error);
        };

        xhr.open('GET', url);

        xhr.responseType = 'blob';

        xhr.send();
    });
};

export const convertUrltoBase64 = async (url) => {
    try {
        return await encodeImageToBase64(url);
    } catch (error) {
        console.error('Error encoding image:', error);
    }
};
