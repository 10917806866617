import React, { useEffect } from 'react';
import classNames from 'classnames';
import ellipse from 'assets/svg/ellipse.svg';
import nz from 'assets/svg/logo-nz.svg';
import './index.less';

const NailsZoneSpin = ({ isLoading, children, isHasBg = true, className, isSmall }) => {
    const footer = document.getElementById('wrapper-nav-footer');
    const header = document.getElementById('navbar-mobile');

    useEffect(() => {
        const bodyElement = document.body;

        if (isLoading) {
            bodyElement.classList.add('overflow-hidden');
            footer?.classList.add('!hidden');
            header?.classList.add('!hidden');
        } else {
            bodyElement.classList.remove('overflow-hidden');
            footer?.classList.remove('!hidden');
            header?.classList.remove('!hidden');
        }

        return () => {
            bodyElement.classList.remove('overflow-hidden');
            footer?.classList.remove('!hidden');
            header?.classList.remove('!hidden');
        };
    }, [isLoading]);

    return (
        <>
            {isHasBg ? (
                <>
                    <div
                        className={classNames(
                            `h-screen w-screen transition relative z-[9999] bg-spin-nz nz-background`,
                            {
                                '!z-0 !bg-none !hidden': !isLoading,
                            }
                        )}
                    >
                        <div
                            className={classNames(
                                `absolute left-0 right-0 translate-x-0 z-[9999] flex items-center justify-center top-1/2 -translate-y-1/2`,
                                {
                                    '!z-[-99] ': !isLoading,
                                }
                            )}
                        >
                            <div className="pt-5">
                                <img src={nz} alt="logo" />
                            </div>
                            <div
                                className={classNames(`h-20 w-20 !duration-100 absolute -top-1 `, {
                                    'animate-[spin_1s_linear_infinite]': isLoading,
                                })}
                            >
                                <img src={ellipse} alt="ellipse" />
                            </div>
                        </div>
                    </div>

                    <div
                        className={classNames(`block`, {
                            hidden: isLoading,
                        })}
                    >
                        {children}
                    </div>
                </>
            ) : (
                <>
                    <div
                        className={classNames(` h-full w-full transition relative hidden `, {
                            '!block': isLoading,
                        })}
                    >
                        <div
                            className={classNames(
                                `bg-white/60  absolute left-4 right-4 rounded-lg top-1/2 bottom-0 translate-x-0 z-[1] flex items-center justify-center ${className}`,
                                {
                                    '!z-[-99] ': !isLoading,
                                }
                            )}
                        >
                            <div
                                className={classNames(`pt-5 absolute top-1/3 bottom-0 mx-auto`, {
                                    'top-[20%]': isSmall,
                                })}
                            >
                                <img src={nz} alt="logo" />
                            </div>
                            <div
                                className={classNames(`h-20 w-20 !duration-100  absolute top-1/3 bottom-0 mx-auto`, {
                                    'top-[20%]': isSmall,
                                    'animate-[spin_1s_linear_infinite]': isLoading,
                                })}
                            >
                                <img src={ellipse} alt="ellipse" />
                            </div>
                        </div>
                    </div>
                    {children}
                </>
            )}
        </>
    );
};

export default NailsZoneSpin;
