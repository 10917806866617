import { useEffect } from 'react';
import AdsService from 'services/ads';
import useAsync from './useAsync';
import useLocalStorage from './useLocalStorage';

const useGetCountry = () => {
    const getContryAPI = useAsync(AdsService.getCountry);
    const [states, setStates] = useLocalStorage('states', []);

    const getCountry = async () => {
        if (states?.length) return;
        const resContry = await getContryAPI.run();
        if (resContry?.data?.country?.available_regions) {
            setStates(resContry?.data?.country?.available_regions);
        }
    };

    useEffect(() => {
        getCountry();
    }, []);

    return { states, setStates };
};

export default useGetCountry;
