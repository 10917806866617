import React from 'react';
import Modal from 'react-modal';

const ReactModal = ({ children, onClose, isFullHeight = false, borderRadius = '16px' }) => {
    Modal.setAppElement('#root');
    const style = {
        content: {
            width: 'fit-content',
            height: 'fit-content',
            padding: 0,
            overflow: 'hidden',
            border: 'none',
            borderRadius,
            zIndex: -10,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            margin: 'auto',
        },
        overlay: {
            height: '100dvh',
            background: 'rgba(0, 0, 0, 0.4)',
        },
    };

    return (
        <Modal isOpen={true} onRequestClose={onClose} style={style} bodyOpenClassName="!overflow-hidden">
            {children}
        </Modal>
    );
};

export default ReactModal;
