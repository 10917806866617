import { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollOldPosition = () => {
    const location = useLocation();
    let top = 0;
    useEffect(() => {
        if (location?.pathname !== '/') return;
        const scrollY = () => {
            top = Math.round(window.scrollY);
        };
        window.addEventListener('scroll', scrollY);
        return () => {
            location?.pathname === '/' && localStorage.setItem(location?.pathname, top);
            window.removeEventListener('scroll', scrollY);
        };
    }, [location?.pathname]);

    return null;
};

export default ScrollOldPosition;
