export const keyUser = 'm8nvn*&hKwcgb^D-D#Hz^5CXfKySpY';
export const keyToken = 'b7a2bdf4-ac40-4012-9635-ff4b7e55eae0';
export const keyRefreshToken = '15c665b7-592f-4b60-b31f-a252579a3bd0';
export const keyCartId = '12saa2l1-4jk2-9iua-bk31-jk1fj041ojaw';
export const keyExpired = '45oqk7f2-6qp5-1kud-nv93-xl5mr472vldk';
export const keyCache = '124soiq-1a4l-g59k-mo31-co3ok1dfk132';

export const keyMetaData = 'd1fm1as-vo22-ldaovp1-q44k0-kq22n-90454i';
export const keyInboxs = '531izpd-5z0v-1903-qg22-pe6lx5avn932';
export const keyCustomerInfo = '531izpd-193210-o93l-qg22-pe6lx5avn932';
export const keyViewedVideoSetting = 'YWJjZGVmZ2hpamtsbW5vcHFyc3R1dHV2d3h5ej';

export const timeBuild = '0fc57d7b-0b51-41ef-8b7e-ed7b7d5dba16';
export const keyAppVersion = '1i23-234jsd-094osd-28sae-t8zpwq';
export const keyGuestCart = 'vwiw23-32sq0-vkt94-k4k2na-29ius8';
export const keyGuestCartId = '12asj-123jd3-kiwj3-3ffe0-389hkr';
export const keyGuestEventCart = 'f3j2-123g3-ktiw26-gj1lz-68x2q';

export const linkApi = process.env.REACT_APP_URL_API;
