const keyLocalStorage = {
    // Last viewed section
    lastViewSetion: {
        lastPostInHome: 'last-post-in-home',
        lastPostInProfile: 'last-post-in-profile',
        lastEventComing: 'last-event-coming',
        lastEventFinished: 'last-event-finished',
        lastViewProfileHero: 'last-view-profile-hero',
    },
    lastScrolled: {
        lastSectionInAccountSettings: 'last-sec-in-account-settings',
    },
};

export default keyLocalStorage;
