import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

const initialPostModalState = {
    feed_detail: {},
    reply_comment: {},
    comments: [],
};

const formatComments = (comments) => {
    comments?.forEach((item) => {
        item?.comments_child?.forEach((item, index) => {
            if (index === 0) {
                item.is_show = true;
            }
        });
    });
    return comments;
};

export const postModalSlice = createSlice({
    name: 'post',
    initialState: initialPostModalState,
    reducers: {
        changeFeedPrivacy(state, action) {
            state.feed_detail.privacy = action.payload;
        },
        closeModal(state) {
            state.comments = [];
            state.feed_detail = {};
        },
        reactFeed(state) {
            if (state.feed_detail?.total_like !== undefined) {
                state.feed_detail.total_like += 1;
            }
        },
        unReactFeed(state) {
            if (state.feed_detail?.total_like !== undefined) {
                state.feed_detail.total_like = Math.max(0, state.feed_detail.total_like - 1);
            }
        },
        setFeed(state, action) {
            state.feed_detail = action.payload;
        },
        setComments(state, action) {
            const comments = formatComments(action.payload);
            state.comments = comments;
        },
        loadMore(state, action) {
            const { parent_comment_id } = action.payload;
            const newComments = cloneDeep(state.comments);
            const parentIndex = newComments.findIndex((comment) => comment.comment_id === parent_comment_id);
            if (parentIndex !== -1) {
                let count = 0;
                newComments[parentIndex]?.comments_child.forEach((item) => {
                    if (!item.is_show && count < 5) {
                        item.is_show = true;
                        count += 1;
                    }
                });
                state.comments = newComments;
            }
        },
        editParentComment(state, action) {
            const { comment_id, comment } = action.payload;
            const newComments = cloneDeep(state.comments);
            const parentIndex = newComments.findIndex((comment) => comment.comment_id === comment_id);
            if (parentIndex !== -1 && newComments[parentIndex]) {
                newComments[parentIndex] = comment;
                state.comments = newComments;
            }
        },
        editChildComment(state, action) {
            const { parent_comment_id, comment } = action.payload;

            const newComments = cloneDeep(state.comments);
            const parentIndex = newComments.findIndex((comment) => comment.comment_id === parent_comment_id);

            if (parentIndex !== -1 && newComments[parentIndex]) {
                const childIndex = newComments[parentIndex]?.comments_child?.findIndex(
                    (x) => x.comment_id === comment?.comment_id
                );

                if (childIndex !== -1 && newComments[parentIndex].comments_child[childIndex]) {
                    newComments[parentIndex].comments_child[childIndex] = comment;
                    state.comments = newComments;
                }
            }
        },
        addChildComment(state, action) {
            const { parent_comment_id, comment } = action.payload;
            const newComments = cloneDeep(state.comments);
            const parentIndex = newComments.findIndex((comment) => comment.comment_id === parent_comment_id);
            if (parentIndex !== -1) {
                newComments[parentIndex]?.comments_child?.unshift({ ...comment, is_show: true });
                state.comments = newComments;
                if (state.feed_detail?.total_comment !== undefined) {
                    state.feed_detail.total_comment += 1;
                }
            }
        },
        deleteChildComment(state, action) {
            const { parent_comment_id, comment_id } = action.payload;
            const newComments = cloneDeep(state.comments);
            const parentIndex = newComments.findIndex((comment) => comment.comment_id === parent_comment_id);
            if (parentIndex !== -1) {
                const childIndex = newComments[parentIndex]?.comments_child?.findIndex(
                    (x) => x.comment_id === comment_id
                );

                if (childIndex !== -1) {
                    newComments[parentIndex]?.comments_child?.splice(childIndex, 1);
                    state.comments = newComments;

                    if (state.feed_detail?.total_comment !== undefined) {
                        state.feed_detail.total_comment = Math.max(0, state.feed_detail.total_comment - 1);
                    }
                }
            }
        },
        deleteParentComment(state, action) {
            const { parent_comment_id } = action.payload;
            const newComments = cloneDeep(state.comments);
            const parentIndex = newComments.findIndex((comment) => comment.comment_id === parent_comment_id);
            if (parentIndex !== -1) {
                const total_child_comment = (newComments[parentIndex].comments_child?.length || 0) + 1;

                newComments?.splice(parentIndex, 1);
                state.comments = newComments;

                if (state.feed_detail?.total_comment !== undefined) {
                    state.feed_detail.total_comment = Math.max(
                        0,
                        state.feed_detail.total_comment - total_child_comment
                    );
                }
            }
        },
        addComment(state, action) {
            const { comment } = action.payload;
            const newComments = cloneDeep(state.comments);
            newComments.unshift(comment);
            state.comments = newComments;

            if (state.feed_detail?.total_comment !== undefined) {
                state.feed_detail.total_comment += 1;
            }
        },
        setReplyComment(state, action) {
            state.reply_comment = action.payload;
        },
    },
});

export const postModalActions = postModalSlice.actions;

export default postModalSlice.reducer;
