import axios from 'axios';

const ReportService = {
    endpoint: process.env.REACT_APP_URL_API,
    getCategoriesReport: async () => {
        const { data } = await axios.post(ReportService.endpoint, {
            query: `
                query {
                    getCategoriesReport {
                        Account {
                            en
                            vn
                        }
                        Post {
                            en
                            vn
                        }
                        Ads {
                            en
                            vn
                        }
                    }
                }
                `,
        });
        return data;
    },
    requestReport: async (input) => {
        const { data } = await axios.post(ReportService.endpoint, {
            query: `
                mutation requestReport($input: RequestReportInput!) {
                    requestReport(input: $input) {
                        report_id
                    }
                }
            `,
            variables: {
                input,
            },
        });
        return data;
    },
};

export default ReportService;
